<div class="big_Container row">
    <div class="col-lg-10 col-sm-10">
        <h2>Update Product Color</h2>
    </div>
    <div class="dialog_confirm_content_container col-lg-1 col-sm-1">
        <span class="material-icons" id="close-icon" (click)="cancel()">close</span>
    </div>
</div>
<div class="container" flex fxLayout="column">
    <form [formGroup]="formGroup_Product_Color" class="Medium_Container" flex fxLayout="column">
        <div class="put_border put_padding put_margin">
            <H4 class="text_align_center">Product Color Data</H4>
            <div class="required row ">
                <div class="col-lg-6 col-sm-5">
                    <label class="float_right General_label margin_top_right_15">Product Color Name</label>
                </div>
                <div class="col-lg-6 col-sm-5">
                    <mat-form-field appearance="fill" color="accent" floatLabel="always">
                        <input matInput formControlName="Product_Color_Name">
                        <mat-error *ngIf="formGroup_Product_Color.controls['Product_Color_Name'].errors?.required">
                            This
                            field is
                            Mandatory</mat-error>
                        <mat-error *ngIf="formGroup_Product_Color.controls['Product_Color_Name'].errors?.maxlength">
                            Maximum length is
                            25character</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="required row ">
                <div class="col-lg-6 col-sm-5">
                    <label class="float_right General_label margin_top_right_15">Product Color Name Arabic</label>
                </div>
                <div class="col-lg-6 col-sm-5">
                    <mat-form-field appearance="fill" color="accent" floatLabel="always">
                        <input matInput formControlName="Product_Color_Description">
                        <mat-error
                            *ngIf="formGroup_Product_Color.controls['Product_Color_Description'].errors?.maxlength">
                            Maximum
                            length is 300character</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="required row ">
                <div class="col-lg-6 col-sm-5">
                    <label class="float_right General_label margin_top_right_15">Product Color Order</label>
                </div>
                <div class="col-lg-6 col-sm-5">
                    <mat-form-field appearance="fill" color="accent" floatLabel="always">
                        <input matInput type="number" formControlName="Product_Color_Order">
                        <mat-error
                            *ngIf="formGroup_Product_Color.controls['Product_Color_Order'].errors">
                            Please put a vaild Color Order
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="big_Container put_margin" style="height: 70px;">
            <div class="float_right put_margin">
                <button class="btn btn-primary" (click)="update_product_color(formGroup_Product_Color)">
                    Update Product Color</button>
                <button class="btn btn-danger put_margin " (click)="delete_product(formGroup_Product_Color.controls['Product_Color_Id'].value,formGroup_Product_Color.controls['Product_Color_Id_Date'].value)">
                    Delete Product Color</button>
            </div>
            <div class="float_right put_margin">
                <button (click)="cancel()" class="btn btn-secondary">Back</button>
            </div>
        </div>
    </form>
</div>