<div class="big_Container dialog_confirm_content_container text_align_center row" style="direction: rtl;">
    <div class="col-lg-10 col-sm-10">
        <h2>تفاصيل الفرع</h2>
    </div>
    <div class="col-lg-1 col-sm-1">
        <span class="material-icons" id="close-icon" (click)="cancel()">close</span>
    </div>
</div>
<div class="container" flex fxLayout="column">
    <form [formGroup]="FG_Branches" class="Medium_Container" flex fxLayout="column">
        <div class="put_border put_padding put_margin_not_auto" fxLayout="column" fxFlex="100" style="direction: rtl;">
            <H4 class="text_align_center">أسم الفرع ووصفه</H4>
            <div class="required row ">
                <div class="col-lg-4 col-sm-5">
                    <label class="float_left General_label margin_top_right_15">أسم الفرع</label>
                </div>
                <div class="col-lg-8 col-sm-5">
                    <mat-form-field appearance="fill" color="accent" floatLabel="always">
                        <input class="text_align_right" matInput formControlName="Branch_Name_Arabic">
                        <mat-error *ngIf="FG_Branches.controls['Branch_Name_Arabic'].errors?.required">
                            هذة الخانة ضرورية
                        </mat-error>
                        <mat-error *ngIf="FG_Branches.controls['Branch_Name_Arabic'].errors?.maxlength">
                            الحد الاقصي من الحروف هو 25
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="required row ">
                <div class="col-lg-4 col-sm-5">
                    <label class="float_left General_label margin_top_right_15">
                        اسم الفرع بالانجليزي
                    </label>
                </div>
                <div class="col-lg-8 col-sm-5">
                    <mat-form-field appearance="fill" color="accent" floatLabel="always">
                        <input class="text_align_right" matInput formControlName="Branch_Name">
                        <mat-error *ngIf="FG_Branches.controls['Branch_Name'].errors?.required">
                            هذة الخانة ضرورية
                        </mat-error>
                        <mat-error *ngIf="FG_Branches.controls['Branch_Name'].errors?.maxlength">
                            الحد الاقصي من الحروف هو 25
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="required row ">
                <div class="col-lg-4 col-sm-5">
                    <label class="float_left General_label margin_top_right_15">وصف الفرع</label>
                </div>
                <div class="col-lg-8 col-sm-5">
                    <mat-form-field appearance="fill" color="accent" floatLabel="always">
                        <!-- <input matInput formControlName="Branch_Description_Arabic"> -->
                        <textarea matInput class="text_align_right" formControlName="Branch_Description_Arabic">
                        </textarea>
                        <mat-error *ngIf="FG_Branches.controls['Branch_Description_Arabic'].errors?.required">
                            هذة الخانة ضرورية
                        </mat-error>
                        <mat-error *ngIf="FG_Branches.controls['Branch_Description_Arabic'].errors?.maxlength">
                            الحد الاقصي من الحروف هو 300
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="required row ">
                <div class="col-lg-4 col-sm-5">
                    <label class="float_left General_label margin_top_right_15">وصف الفرع بالانجليزي</label>
                </div>
                <div class="col-lg-8 col-sm-5">
                    <mat-form-field appearance="fill" color="accent" floatLabel="always">
                        <!-- <input matInput formControlName="Branch_Description"> -->
                        <textarea matInput class="text_align_right" formControlName="Branch_Description">
                        </textarea>
                        <mat-error *ngIf="FG_Branches.controls['Branch_Description'].errors?.required">
                            هذة الخانة ضرورية
                        </mat-error>
                        <mat-error *ngIf="FG_Branches.controls['Branch_Description'].errors?.maxlength">
                            الحد الاقصي من الحروف هو 300
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="required row ">
                <div class="col-lg-4 col-sm-5">
                    <label class="float_left General_label margin_top_right_15">
                        تعليق علي الفرع
                    </label>
                </div>
                <div class="col-lg-8 col-sm-5">
                    <mat-form-field appearance="fill" color="accent" floatLabel="always">
                        <input class="text_align_right" matInput formControlName="Branch_Note">
                        <mat-error *ngIf="FG_Branches.controls['Branch_Note'].errors?.maxlength">
                            الحد الاقصي من الحروف هو 300
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <!-- <div class="required row ">
                <div class="col-lg-6 col-sm-5">
                    <label class="float_right General_label margin_top_right_15">Is this a Main Branch</label>
                </div>
                <div class="col-lg-6 col-sm-5">
                    <mat-form-field appearance="fill" color="accent" floatLabel="always">
                        <mat-checkbox fxFlex="65" fxLayoutAlign="start center" formControlName="Branch_Is_Main_Branch"
                            class="check_box_style"></mat-checkbox>
                    </mat-form-field>
                </div>
            </div> -->
        </div>
        <div class="put_border put_padding put_margin_not_auto" fxLayout="column" fxFlex="100" style="direction: rtl;">
            <h4 class="text_align_center">
                عنوان الفرع ومكانه
            </h4>
            <div class="required row ">
                <div class="col-lg-4 col-sm-5">
                    <label class="float_left General_label margin_top_right_15">
                        عنوان الفرع
                    </label>
                </div>
                <div class="col-lg-8 col-sm-5">
                    <mat-form-field appearance="fill" color="accent" floatLabel="always">
                        <input class="text_align_right" matInput formControlName="Branch_Address">
                        <mat-error *ngIf="FG_Branches.controls['Branch_Address'].errors?.required">
                            هذة الخانة ضرورية
                        </mat-error>
                        <mat-error *ngIf="FG_Branches.controls['Branch_Address'].errors?.maxlength">
                            الحد الاقصي من الحروف هو 80
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="required row ">
                <div class="col-lg-4 col-sm-5">
                    <label class="float_left General_label margin_top_right_15">
                        اسم المدينة
                    </label>
                </div>
                <div class="col-lg-8 col-sm-5">
                    <mat-form-field appearance="fill" color="accent" floatLabel="always">
                        <mat-select disabled #test [value]="selected_city" placeholder="Branch City" class="text_align_right"
                            (selectionChange)="change_city(test.value)">
                            <mat-option style="background-color: white;" [value]="City.city_Id" *ngFor="let City of Cities">{{City.city_Name}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="FG_Branches.controls['Branch_City'].errors">
                            أختار مدينة
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="required row ">
                <div class="col-lg-4 col-sm-5">
                    <label class="float_left General_label margin_top_right_15">
                        أسم المنطقة
                    </label>
                </div>
                <div class="col-lg-8 col-sm-5">
                    <mat-form-field appearance="fill" color="accent" floatLabel="always">
                        <mat-select disabled #Branch_area_id [value]="selected_area" placeholder="Branch Area" class="text_align_right">
                            <mat-option style="background-color: white;" [value]="Area.area_ID" *ngFor="let Area of Areas">{{Area.area_Name}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="FG_Branches.controls['Branch_Area'].errors">
                            أختار المنطقة
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="required row ">
                <div class="col-lg-4 col-sm-5">
                    <label class="float_left General_label margin_top_right_15">
                        مكان الفرع بيان اول:
                    </label>
                </div>
                <div class="col-lg-8 col-sm-5">
                    <mat-form-field appearance="fill" color="accent" floatLabel="always">
                        <input class="text_align_right" matInput formControlName="Branch_Lat" #lat>
                    </mat-form-field>
                </div>
            </div>
            <div class="required row ">
                <div class="col-lg-4 col-sm-5">
                    <label class="float_left General_label margin_top_right_15">
                        مكان الفرع بيان 2:
                    </label>
                </div>
                <div class="col-lg-8 col-sm-5">
                    <mat-form-field appearance="fill" color="accent" floatLabel="always">
                        <input class="text_align_right" matInput formControlName="Branch_Long" #long>
                    </mat-form-field>
                </div>
            </div>            
        </div>
        <div class="put_border put_padding put_margin_not_auto" fxLayout="column" fxFlex="100" style="direction: rtl;">
            <H4 class="text_align_center">
                ارقام تليفون للتواصل مع الشركة
            </H4>
            <div class="required row ">
                <div class="col-lg-4 col-sm-5">
                    <label class="float_left General_label margin_top_right_15">
                        رقم تليفون رئيسي
                    </label>
                </div>
                <div class="col-lg-8 col-sm-5">
                    <mat-form-field appearance="fill" color="accent" floatLabel="always">
                        <input class="text_align_right" type="number" matInput formControlName="Branch_Phone_1">
                        <mat-error *ngIf="FG_Branches.controls['Branch_Phone_1'].errors?.required">
                            هذة الخانة ضرورية
                        </mat-error>
                        <mat-error *ngIf="FG_Branches.controls['Branch_Phone_1'].errors?.maxlength">
                            الحد الاقصي من الارقام هو 25
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="required row ">
                <div class="col-lg-4 col-sm-5">
                    <label class="float_left General_label margin_top_right_15">
                        رقم تليفون ثانوي
                    </label>
                </div>
                <div class="col-lg-8 col-sm-5">
                    <mat-form-field appearance="fill" color="accent" floatLabel="always">
                        <input class="text_align_right" type="number" matInput formControlName="Branch_Phone_2">
                        <mat-error *ngIf="FG_Branches.controls['Branch_Phone_2'].errors?.maxlength">
                            الحد الاقصي من الارقام هو 25
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="required row ">
                <div class="col-lg-4 col-sm-5">
                    <label class="float_left General_label margin_top_right_15">
                        رقم تليفون ثالث
                    </label>
                </div>
                <div class="col-lg-8 col-sm-5">
                    <mat-form-field appearance="fill" color="accent" floatLabel="always">
                        <input class="text_align_right" type="number" matInput formControlName="Branch_Phone_3">
                        <mat-error *ngIf="FG_Branches.controls['Branch_Phone_3'].errors?.maxlength">
                            الحد الاقصي من الارقام هو 25
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="required row ">
                <div class="col-lg-4 col-sm-5">
                    <label class="float_left General_label margin_top_right_15">                        
                        رقم تليفون صاحب الشركة
                    </label>
                </div>
                <div class="col-lg-8 col-sm-5">
                    <mat-form-field appearance="fill" color="accent" floatLabel="always">
                        <input class="text_align_right" type="number" matInput formControlName="Branch_Owner_Phone">
                        <mat-error *ngIf="FG_Branches.controls['Branch_Owner_Phone'].errors?.maxlength">
                            الحد الاقصي من الارقام هو 25
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="big_Container put_margin" style="height: 70px;">
            <div class="float_right put_margin">
            </div>
            <div class="float_right put_margin">
                <button (click)="cancel()" class="btn btn-secondary">Back</button>
            </div>
        </div>
    </form>
</div>