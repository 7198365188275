<section class="about-us" style="direction: rtl;">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-12">
                <!-- <app-category-side-bar></app-category-side-bar> -->
            </div>
            <div class="col-lg-6 col-md-12 feed">
                <h2>تسجيل دخول</h2>
                <!-- [formGroup]="this.service.Login_FG" -->
                <form class="arabic_form" [formGroup]="service.Login_FG">
                    <div class="form-controls">
                        <label>اسم المستخدم *</label>
                        <mat-form-field appearance="fill" floatLabel="always">
                            <input style="text-align: right;" type="text" matInput formControlName="UserName">
                            <mat-error *ngIf="service.Login_FG.controls['UserName'].errors?.required">هذة الخانة ضرورية
                            </mat-error>
                            <mat-error *ngIf="service.Login_FG.controls['UserName'].errors?.maxlength">الحد الاقصي من الحروف هو 50</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="form-controls">
                        <label>Password *</label>
                        <mat-form-field appearance="fill" floatLabel="always">
                            <input autocomplete="off" type="password" matInput formControlName="Password">
                            <mat-error *ngIf="service.Login_FG.controls['Password'].errors?.required">
                                هذة الخانة ضرورية</mat-error>
                            <mat-error *ngIf="service.Login_FG.controls['Password'].errors?.minlength">
                                الحد الادني من الحروف هو 6</mat-error>
                            <mat-error *ngIf="service.Login_FG.controls['Password'].errors?.maxlength">
                                الحد الاقصي من الحروف هو 50</mat-error>
                        </mat-form-field>
                    </div>
                    
                    <ngx-recaptcha2 #captchaElem [siteKey]="siteKey" formControlName="recaptcha">
                    </ngx-recaptcha2>
                    <mat-error *ngIf="service.Login_FG.controls['recaptcha'].errors">
                        أضغط علي صندوق الاختيار
                    </mat-error>
                    <div class="form-controls">
                        <button class="btns" (click)="signin()">تسجيل دخول</button>
                    </div>
                    <div class="d-flex flex-column links-fr">
                        <a href="http://www.thenilezone.com/ar/general/blog/forgot-password">هل نسيت كلمة السر؟</a>
                        <a href="http://www.thenilezone.com/ar/general/blog/forgot-username">هل نسيت اسم المستخدم؟</a>
                        <a href="http://www.thenilezone.com/ar/general/register">أنشئ حساب جديد</a>
                    </div>
                </form>
            </div>
            <div class="col-lg-3 col-md-12">
                <!-- <app-category-side-bar></app-category-side-bar> -->
            </div>
        </div>
    </div>
</section>