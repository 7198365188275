<div style="direction: rtl;">
    <div class="dialog_confirm_content_container">
        <!-- <mat-icon id="close-icon" (click)="closeDialog()">close</mat-icon> -->
        <span class="material-icons text_align_center col-lg-1" id="close-icon" (click)="closeDialog()">close</span>
        <span class="content-span full-width col-lg-11">{{data.message}}</span>
    </div>
    <div class="dialog_confirm_content_container" style="direction: ltr;">
        <button class="margin_left_15 margin_right_15" mat-flat-button id="no-button" [mat-dialog-close]="false">لا</button>
        <button mat-flat-button id="yes-button" [mat-dialog-close]="true">نعم</button>
    </div>
</div>