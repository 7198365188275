<section class="catalog-pan">
    <div class="container" style="direction: rtl;">
        <form [formGroup]="formGroup_insert_Archived_Products" class="row">
            <div class="col-lg-6 col-md-12">
                <div class="slider-view">
                    <img src="{{Pictures_Url + 'Product_Pictures/' + product_Company_Id + '/' + product_Branch_Id + '/' + picPath}}"
                        *ngIf="If_has_pic" />
                    <img src="{{Pictures_Url + 'Product_Pictures/0/0/Product_Initial_Pic.jpg'}}"
                        *ngIf="!(If_has_pic)" />
                </div>
            </div>
            <div class="col-lg-6 col-md-12 feed pr-view">
                <div class="col-12 mt-4 pl-0 product-section_title">
                    <h2>
                        أسم المنتج
                    </h2>
                </div>
                <div class="small-tagline">
                    <mat-form-field class="full_width" appearance="fill" color="accent" floatLabel="always">
                        <input matInput formControlName="Archived_Product_Name_Arabic" #arabic_name>
                        <mat-error
                            *ngIf="formGroup_insert_Archived_Products.controls['Archived_Product_Name_Arabic'].errors?.required">
                            هذة الخانة ضرورية                            
                        </mat-error>
                        <mat-error
                            *ngIf="
                        formGroup_insert_Archived_Products.controls['Archived_Product_Name_Arabic'].errors?.maxlength">Maximum
                            الحد الاقصي من الحروف هو 50
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12 mt-4 pl-0 product-section_title">
                    <h2>أسم المنتج بالانحليزي</h2>
                </div>
                <div class="small-tagline">
                    <mat-form-field class="full_width" appearance="fill" color="accent" floatLabel="always">
                        <input matInput formControlName="Archived_Product_Name">
                        <mat-error
                            *ngIf="formGroup_insert_Archived_Products.controls['Archived_Product_Name'].errors?.required">
                            هذة الخانة ضرورية
                        </mat-error>
                        <mat-error *ngIf="
                        formGroup_insert_Archived_Products.controls['Archived_Product_Name'].errors?.maxlength">
                            الحد الاقصي من الحروف هو 50
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12 mt-4 pl-0 product-section_title">
                    <h2>
                        حالة المنتج
                    </h2>
                </div>
                <div class="small-tagline">
                    <mat-form-field class="full_width" appearance="fill" color="accent" floatLabel="always">
                        <input matInput attr.disabled formControlName="product_status">                        
                    </mat-form-field>
                </div>
                <div class="col-12 mt-4 pl-0 product-section_title">
                    <h2>
                        وصف المنتج
                    </h2>
                </div>
                <div class="small-tagline">
                    <mat-form-field class="full_width" appearance="fill" color="accent" floatLabel="always">
                        <input matInput formControlName="Archived_Product_Description">
                        <mat-error
                            *ngIf="formGroup_insert_Archived_Products.controls['Archived_Product_Description'].errors?.required">
                            هذة الخانة ضرورية
                        </mat-error>
                        <mat-error
                            *ngIf="
                    formGroup_insert_Archived_Products.controls['Archived_Product_Description'].errors?.maxlength">
                            الحد الاقصي من الحروف هو 50
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="col-12 mt-4 pl-0 product-section_title">
                    <h2>
                        وصف المنتج بالانجليزي
                    </h2>
                </div>
                <div class="small-tagline">
                    <mat-form-field class="full_width" appearance="fill" color="accent" floatLabel="always">
                        <input matInput formControlName="Archived_Product_Description_Arabic">
                            <mat-error
                                *ngIf="formGroup_insert_Archived_Products.controls['Archived_Product_Description_Arabic'].errors?.required">
                                هذة الخانة ضرورية
                            </mat-error>
                        <mat-error
                            *ngIf="
                formGroup_insert_Archived_Products.controls['Archived_Product_Description_Arabic'].errors?.maxlength">
                            الحد الاقصي من الحروف هو 50
                        </mat-error>
                    </mat-form-field>
                </div>
                <!-- PHOTO GALLERY -->
                <div class="slide-product put_margin put_padding">
                    <h2 class="">
                        أختار صورة للمنتج
                    </h2>
                    <owl-carousel-o [options]="customOptions" >
                        <ng-container *ngFor="let slide of product_pictures" >
                            <ng-template carouselSlide id>
                                <div class="desc padding-xs" >
                                    <img src="{{Pictures_Url + 'Product_Pictures/' + product_Company_Id + '/' + product_Branch_Id + '/' + slide.product_Picture_Store_Name}}"
                                        [alt]="slide.product_Related_Archive_Id + slide.product_Related_Archive_Id_Date"
                                        (click)="changeImg(slide.product_Picture_Store_Name,slide.product_Picture_Id,slide.product_Picture_Id_Date)" />
                                        <p>
                                        <mat-icon *ngIf="slide.product_Picture_Is_approved" style="color:green;"
                                            title="Picture Approved" id="close-icon">verified</mat-icon>
                                        <mat-icon *ngIf="slide.product_Picture_Is_approved == false" style="color:red;"
                                            title="Picture Rejected" id="close-icon">warning</mat-icon>
                                        <mat-icon *ngIf="slide.product_Picture_Is_approved == null" style="color:yellow;"
                                            title="Picture Under Revision" id="close-icon">warning</mat-icon>
                                        </p>
                                        <a (click)="dialog_update_Picture(slide.product_Picture_Id,slide.product_Picture_Id_Date)" [routerLink]='null'>(حدث الصورة)</a>
                                </div>
                            </ng-template>
                        </ng-container>
                        <ng-container>
                            <ng-template carouselSlide id>
                                <div class="desc padding-xs">
                                    <img src="{{Pictures_Url + 'Product_Pictures/0/0/Product_Initial_Pic.jpg'}}"
                                        style="max-width: 100%;" />
                                        <a *ngIf="this.product_colors.length > 0" (click)="dialog_add_Picture()" [routerLink]='null'>
                                            (أضف صورة للمنتج)
                                        </a>
                                </div>
                            </ng-template>
                        </ng-container>
                    </owl-carousel-o>
                </div>
                <!-- COLORS LIST -->
                <div class="text-center links put_margin put_padding">
                    <h2 *ngIf="this.product_colors.length > 0" class="">أختار لون المنتج</h2>
                    <ul>
                        <li *ngFor="let option of product_colors" class="d-block">
                            <a (click)="changeColor(option.product_Color_Id,option.product_Color_Id_Date)"
                                [routerLink]='null'>
                                {{option.product_Color_Description}}({{option.product_Color_Status_name_Arabic}})
                            </a>
                            <a (click)="dialog_update_Color(option.product_Color_Id,option.product_Color_Id_Date)" [routerLink]='null'>(حدث اللون)</a>
                        </li>
                        <li>
                            <a (click)="addColor()" [routerLink]='null'>أضف لون</a>
                        </li>
                    </ul>
                </div>

                <!-- PRODUCT NOTE -->
                <div class="col-12 mt-4 pl-0 product-section_title">
                    <h2>
                        ملاحظات علي المنتج
                    </h2>
                </div>
                <div class="small-tagline">
                    <mat-form-field appearance="fill" color="accent" floatLabel="always">
                        <input matInput formControlName="Archived_Product_Note">
                    </mat-form-field>
                </div>
            </div>
            <br />
            <!-- 4 Catigory selection -->
            <div class="feed pr-view put_padding">
                <div class="col-12 mt-4 pl-0 text_align_center put_padding product-section_title">
                    <h2>
                        أختار تصنيف المنتج
                    </h2>
                </div>
                <div class="required row ">
                    <div class="col-4">
                        <label class="float_left General_label margin_top_right_15">تصنيف 1</label>
                    </div>
                    <div class="col-8">
                        <mat-form-field appearance="fill" color="accent" floatLabel="always">
                            <mat-select formControlName="cat_1" (selectionChange)="cat_1_change($event)">
                                <mat-option  class="text_align_start" style="background-color: white;" [value]="Cat_1_list_item.product_Catigory_1_Id"
                                    *ngFor="let Cat_1_list_item of Cat_1_list">{{Cat_1_list_item.product_Catigory_1_Name_Arabic}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="formGroup_insert_Archived_Products.controls['cat_1'].errors">
                                هذة الخانة ضرورية
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="required row ">
                    <div class="col-4">
                        <label class="float_left General_label margin_top_right_15">تصنيف 2</label>
                    </div>
                    <div class="col-8">
                        <mat-form-field appearance="fill" color="accent" floatLabel="always">
                            <mat-select formControlName="cat_2" (selectionChange)="cat_2_change($event)">
                                <mat-option style="background-color: white;" [value]="Cat_2_list_item.product_Catigory_2_Id"
                                    *ngFor="let Cat_2_list_item of Cat_2_list">{{Cat_2_list_item.product_Catigory_2_Name_Arabic}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="formGroup_insert_Archived_Products.controls['cat_2'].errors">
                                هذة الخانة ضرورية
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="required row ">
                    <div class="col-4">
                        <label class="float_left General_label margin_top_right_15">تصنيف 3</label>
                    </div>
                    <div class="col-8">
                        <mat-form-field appearance="fill" color="accent" floatLabel="always">
                            <mat-select formControlName="cat_3" (selectionChange)="cat_3_change($event)">
                                <mat-option style="background-color: white;" [value]="Cat_3_list_item.product_Catigory_3_Id"
                                    *ngFor="let Cat_3_list_item of Cat_3_list">{{Cat_3_list_item.product_Catigory_3_Name_Arabic}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="formGroup_insert_Archived_Products.controls['cat_3'].errors">
                                هذة الخانة ضرورية
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="required row ">
                    <div class="col-4">
                        <label class="float_left General_label margin_top_right_15">تصنيف 4</label>
                    </div>
                    <div class="col-8">
                        <mat-form-field appearance="fill" color="accent" floatLabel="always">
                            <mat-select formControlName="Archived_Product_Related_Catigory_4">
                                <mat-option style="background-color: white;" [value]="Cat_4_list_item.product_Catigory_4_Id"
                                    *ngFor="let Cat_4_list_item of Cat_4_list">{{Cat_4_list_item.product_Catigory_4_Name_Arabic}}
                                </mat-option>
                            </mat-select>
                            <mat-error
                                *ngIf="formGroup_insert_Archived_Products.controls['Archived_Product_Related_Catigory_4'].errors">
                                هذة الخانة ضرورية
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <!-- Price and Delevery data -->
                <div class="required row ">
                    <div class="col-4">
                        <label class="float_left General_label margin_top_right_15">
                            سعر المنتج
                        </label>
                    </div>
                    <div class="col-8">
                        <mat-form-field appearance="fill" color="accent" floatLabel="always">
                            <input min="0" type="number" matInput formControlName="Archived_Products_Price">
                            <mat-error
                                *ngIf="formGroup_insert_Archived_Products.controls['Archived_Products_Price'].errors">
                                هذة الخانة ضرورية
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="required row ">
                    <div class="col-4">
                        <label class="float_left General_label margin_top_right_15">
                            حالة توصيل المنتج للعميل
                        </label>
                    </div>
                    <div class="col-8">
                        <mat-form-field appearance="fill" color="accent" floatLabel="always">
                            <mat-select formControlName="Archived_Products_Delivery_Status">
                                <mat-option style="background-color: white;" [value]="item.product_Delivery_Status_Id"
                                    *ngFor="let item of prod_delivery_statuses">{{item.product_Delivery_Status_Name_Arabic}}
                                </mat-option>
                            </mat-select>
                            <mat-error
                                *ngIf="formGroup_insert_Archived_Products.controls['Archived_Products_Delivery_Status'].errors">
                                هذة الخانة ضرورية
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div class="required row ">
                    <div class="col-4">
                        <label class="float_left General_label margin_top_right_15">
                            عدد النقاط المطلوبة من الشركة للتوصيل
                        </label>
                    </div>
                    <div class="col-8">
                        <mat-form-field appearance="fill" color="accent" floatLabel="always">
                            <input min="0" matInput type="number" formControlName="Archived_Product_Company_Delivery_Point">
                            <mat-error
                                *ngIf="formGroup_insert_Archived_Products.controls['Archived_Product_Company_Delivery_Point'].errors">
                                هذة الخانة ضرورية
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div class="required row ">
                    <div class="col-4">
                        <label class="float_left General_label margin_top_right_15">
                            عدد النقاط المطلوبة من الزبون للتوصيل
                        </label>
                    </div>
                    <div class="col-8">
                        <mat-form-field appearance="fill" color="accent" floatLabel="always">
                            <input min="0" type="number" matInput formControlName="Archived_Product_User_Delivery_Point">
                            <mat-error
                                *ngIf="formGroup_insert_Archived_Products.controls['Archived_Product_User_Delivery_Point'].errors">
                                هذة الخانة ضرورية
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <!-- BUTTONS -->
                <div>
                    <div class="row put_margin" style="direction: ltr;">
                        <div class="text_align_center">
                            <button class="btn btn-danger put_margin " (click)="delete_product()">
                                أحذف المنتج
                            </button>
                            <button class="btn btn-primary put_margin " (click)="Update_Product(formGroup_insert_Archived_Products)">
                                حدث المنتح
                            </button>
                        </div>
                    </div>
                    <div class="feed pr-view">
                        <div class="float_right">
                            <a href="{{'/ar/main/view_product/' + product_id + '/' + product_id_date}}"
                                class="put_margin back"> رجوع</a>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</section>