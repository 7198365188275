<section>
    <div class="container text_align_center">
        <h1 class="text_align_center put_padding put_margin_not_auto">Points Management</h1>
        <p>Here you can conctrol points of your Company</p>
        <h3 class="text_align_center put_padding put_margin_not_auto">Your Company</h3>
        <p>Here you can see All your companies that are approved by TheNileZone administration team</p>
        <div (click)="focusout()" class=" text_align_center">
            <span class="display_inline_block put_margin_not_auto">
                <mat-form-field appearance="fill">
                    <mat-label>Your Company
                    </mat-label>
                    <mat-select (selectionChange)="copmany_id_change($event.value)" autocomplete="off">
                        <mat-option style="background-color: white;" *ngFor="let data of Company_List" [value]="data.company_id">
                            {{data.company_name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </span>
            <span *ngIf="this.Company_List.length > 1" class="man desc display_inline_block put_margin_not_auto">
                <p>You are linked to more than one company Please Select one Company</p>
            </span>
            <span *ngIf="this.Company_List.length == 0" class="man desc display_inline_block put_margin_not_auto">
                <p>You still have no Company or Branch please create one from <a routerLink='/main/home'
                        [routerLinkActive]="['active']">here</a></p>
            </span>
        </div>
        <form [formGroup]="FG_Branches">
            <div class="put_padding" *ngIf="this.FG_Branches.controls['Company_Id'].value > 0">
                <div (click)="focusout()" class="container put_margin put_border row full_width">
                    <h1 class=" put_margin_not_auto">Company Basic Details</h1>
                    <div class="col-lg-8">
                        <h3><strong>Company Name: </strong> {{this.FG_Branches.controls['Company_Name'].value}}</h3>
                        <h3><strong>Company Status: </strong> {{this.FG_Branches.controls['Company_Status'].value}}</h3>
                        <h3><strong>Company Points: </strong> {{this.FG_Branches.controls['Company_Points'].value}}</h3>
                        <h3><strong>Number of Products on Front Page: </strong>
                            {{this.FG_Branches.controls['Company_No_of_Products_on_front_page'].value}}</h3>
                        <h3><strong>Number of Products on Online: </strong>
                            {{this.FG_Branches.controls['Company_No_of_Online_Products'].value}}</h3>
                        <h3><strong>Number of Approved Products: </strong>
                            {{this.FG_Branches.controls['Company_No_of_approved_Products'].value}}</h3>
                        <h3><strong>Number of Denied Products: </strong>
                            {{this.FG_Branches.controls['Company_No_of_pending_Products'].value}}</h3>
                        <h3><strong>Number of Pending Products: </strong>
                            {{this.FG_Branches.controls['Company_No_of_denied_Products'].value}}</h3>
                        <!-- <h3><strong>Company Name: </strong> {{this.FG_Branches.controls['Company_logo_path'].value}} 
                        </h3>-->
                    </div>
                    <div class="col-lg-3 put_margin ">
                        <img src="{{Pictures_Url + 'Companies_Id/' + this.FG_Branches.controls['Company_Id'].value + '/'+ this.FG_Branches.controls['Company_logo_path'].value}}"
                            style="max-width: 100%" />
                    </div>
                </div>

                <h1 (click)="focusout()" class="text_align_center put_margin_not_auto">Points Management</h1>
                <div (click)="focusout()" class="container put_margin put_border row full_width">
                    <div class="text_align_center">
                        <h1 class="put_margin_not_auto">Online Products Time Slots</h1>
                        <p class="put_margin">Simply get Online Time slots to put your Products Online</p>
                        <p class="put_margin">Online Time Slots can be reused to put different Products Online i.e. You
                            can remove a Product from being Online and put another one anytime you like</p>
                        <div class="put_margin put_border row " *ngFor="let product of filterItemsOfType(false)">
                            <div class="col-lg-4 ">
                                <!-- <button class="btn btn-primary put_margin" (click)="signin()">Decrease Time Slot
                                    Period</button> -->
                            </div>
                            <div class="col-lg-4">
                                <h3><strong>Start
                                        Date</strong>{{this.datePipe.transform(product.point_Consumbtion_Start_Date,
                                    'yyyy-MM-dd')}}</h3>
                                <h3><strong>End
                                        Date</strong>{{this.datePipe.transform(product.point_Consumbtion_End_Date,
                                    'yyyy-MM-dd')}}</h3>
                                <h3><strong>Not Used availiable
                                        Slots</strong>{{product.point_Consumbtion_No_Time_Slots_Active_Not_Used}}</h3>
                                <h3><strong>Total Slots</strong>{{product.point_Consumbtion_No_Of_Time_Slots}}</h3>
                            </div>
                            <div class="col-lg-4 text_align_center">
                                <button class="btn btn-primary put_margin"
                                    (click)="extend_online_ts(product.point_Consumbtion_Id,product.point_Consumbtion_Id_Date, product.point_Consumbtion_No_Of_Time_Slots)">Extend
                                    Time Slot
                                    Period</button>
                                <p class="put_margin">Extend the period of the existing Time slot</p>
                                <p class="put_margin">(This is easier than openning new Time Slot and assigning the same
                                    products to the new one)</p>
                            </div>
                        </div>
                    </div>
                    <div class="put_padding row">
                        <div class="col-lg-4">
                            <h3><strong>Company Points: </strong> {{this.FG_Branches.controls['Company_Points'].value}}
                            </h3>
                        </div>
                        <div class="col-lg-4">
                            <button class="btn btn-primary put_margin" (click)="add_online_ts()">Add Online
                                Time Slot</button>
                        </div>
                        <div class="col-lg-4">
                            <p class="put_margin">(Add Online Time slots to put your products online)</p>
                        </div>
                    </div>
                </div>
                <div (click)="focusout()" class="container put_margin put_border row full_width">
                    <div class="text_align_center">
                        <h1 class="put_margin_not_auto">Front Page Time Slots</h1>
                        <p class="put_margin">Simply get Front Page Time slots to put your products on TheNileZone
                            Front page</p>
                        <p class="put_margin">Front Page Time Slots can be reused to put different Products on
                            TheNileZone Front page i.e. you can remove a Product from the Front Page and put another one
                            anytime you like</p>
                        <div class="put_margin put_border row" *ngFor="let product of filterItemsOfType(true)">
                            <div class="col-lg-4">
                                <!-- <button class="btn btn-primary put_margin" (click)="signin()">Decrease Time Slot
                                    Period</button> -->
                            </div>
                            <div class="col-lg-4">
                                <h3><strong>Start
                                        Date</strong>{{this.datePipe.transform(product.point_Consumbtion_Start_Date,
                                    'yyyy-MM-dd')}}</h3>
                                <h3><strong>End
                                        Date</strong>{{this.datePipe.transform(product.point_Consumbtion_End_Date,
                                    'yyyy-MM-dd')}}</h3>
                                <h3><strong>Not Used availiable
                                        Slots</strong>{{product.point_Consumbtion_No_Time_Slots_Active_Not_Used}}</h3>
                                <h3><strong>Front Page weight</strong>{{product.point_Consumbtion_No_Of_Time_Slots}}
                                </h3>
                            </div>
                            <div class="col-lg-4 text_align_center">
                                <button class="btn btn-primary put_margin"
                                    (click)="extend_frontpage_ts(product.point_Consumbtion_Id,product.point_Consumbtion_Id_Date, product.point_Consumbtion_No_Of_Time_Slots)">Extend
                                    Time
                                    Slot Period</button>
                                <p class="put_margin">Extend the period of the existing Time slot</p>
                                <p class="put_margin">(This is easier than openning new Time Slot and assigning the same
                                    products to the new one)</p>
                            </div>
                        </div>
                    </div>
                    <div class="put_padding row">
                        <div class="col-lg-4 ">
                            <h3><strong>Company Points: </strong> {{this.FG_Branches.controls['Company_Points'].value}}
                            </h3>
                        </div>
                        <div class="col-lg-4 ">
                            <button class="btn btn-primary put_margin" (click)="add_frontpage_ts()">Add Front Page time
                                slot</button>
                        </div>
                        <div class="col-lg-4">
                            <p class="put_margin">(Add Front Page Time slots to be put your products on TheNileZone
                                Front Page)</p>
                        </div>
                    </div>
                </div>

                <div class="container put_margin put_border row full_width">
                    <div (click)="focusout()">
                        <h1 class="text_align_center put_margin_not_auto">Put Products Online</h1>
                        <p class="text_align_center put_margin_not_auto">To put the product Online:</p>
                        <p class="text_align_center put_margin_not_auto">1-Select one Online Time Slot</p>
                        <p class="text_align_center put_margin_not_auto">2-Press "Put the product online" corresponding
                            to
                            the needed approved products</p>
                        <br>
                    </div>
                    <div (click)="focusout()" class="row" style="direction: rtl;">
                        <div class="col-lg-4">
                            <h1 *ngIf="this.selected_online_ts_id != 0" class="text_align_center put_margin_not_auto">
                                Online Products in the selected Time Slot</h1>
                            <p *ngIf="this.selected_online_ts_id != 0" class="text_align_center put_margin_not_auto">
                                Here you can put your Product (in the selected time slot) offline</p>
                        </div>
                        <div class="col-lg-4">
                            <h1 class="text_align_center put_margin_not_auto">Availiable Online Time Slots</h1>
                            <p class="text_align_center put_margin_not_auto">Please select one Online Time Slot</p>
                        </div>
                        <div class="col-lg-4">
                            <h1 class="text_align_center put_margin_not_auto">Approved Products but not online</h1>
                            <p class="text_align_center put_margin_not_auto">The product must be approved by TheNileZone
                                Management team first before you can see it below and be able to put it on online on
                                your company page</p>
                        </div>
                    </div>
                    <div class="row" style="direction: rtl;">
                        <div class="col-lg-4">
                            <div *ngIf="this.selected_online_ts_id != 0">
                                <div class="put_margin put_border row" *ngFor="let product of filter_online_products()">
                                    <!-- <div class="col-lg-4">
                                    <button class="btn btn-primary" (click)="put_product_online()">
                                        << Put your Product Online</button>
                                </div> -->
                                    <div class="col-lg-6">
                                        <img src="{{Pictures_Url + 'Product_Pictures/' + product.company_Id + '/' + product.branch_Id + '/' + product.product_Pic_path}}"
                                            style="max-width: 100%" />
                                        <h3><strong>{{product.product_Name}}</strong> </h3>
                                    </div>
                                    <div class="col-lg-6">
                                        <button class="btn btn-primary"
                                            (click)="put_product_offline(product.product_Id , product.product_Id_Date)">Put
                                            your Product
                                            offline
                                            >></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 text_align_center" (focusout)="focusout_onair()">
                            <div class="put_margin put_border row"
                                [ngClass]="{'slot_select' : (selected_online_ts_id === product.point_Consumbtion_Id && selected_online_ts_id_date === product.point_Consumbtion_Id_Date)}"
                                (click)="select_online_ts(product.point_Consumbtion_Id,product.point_Consumbtion_Id_Date)"
                                *ngFor="let product of filterItemsOfType(false)">
                                <h3><strong>Start
                                        Date: </strong>{{this.datePipe.transform(product.point_Consumbtion_Start_Date,
                                    'yyyy-MM-dd')}}</h3>
                                <h3><strong>End
                                        Date: </strong>{{this.datePipe.transform(product.point_Consumbtion_End_Date,
                                    'yyyy-MM-dd')}}</h3>
                                <h3><strong>Not Used availiable
                                        Slots: </strong>{{product.point_Consumbtion_No_Time_Slots_Active_Not_Used}}</h3>
                                <h3><strong>Total Slots: </strong>{{product.point_Consumbtion_No_Of_Time_Slots}}</h3>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="put_margin put_border row" *ngFor="let product of approved_products">
                                <div class="col-lg-6">
                                    <button class="btn btn-primary"
                                        (click)="put_product_online(product.product_Id , product.product_Id_Date)">
                                        << Put your Product Online</button>
                                </div>
                                <div class="col-lg-6">
                                    <img src="{{Pictures_Url + 'Product_Pictures/' + product.company_Id + '/' + product.branch_Id + '/' + product.product_Pic_path}}"
                                        style="max-width: 100%" />
                                    <h3><strong>{{product.product_Name}}</strong> </h3>
                                </div>
                                <!-- <div class="col-lg-4">
                                    <button class="btn btn-primary" (click)="put_product_offline()">Put your Product offline
                                        >></button>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container put_margin put_padding put_border row full_width">
                    <div (click)="focusout()">
                        <h1 class="text_align_center put_margin_not_auto">Put your Products On Front Page</h1>
                        <p class="text_align_center put_margin_not_auto">Steps:</p>
                        <p class="text_align_center put_margin_not_auto">1-Select one Front Page Time Slot</p>
                        <p class="text_align_center put_margin_not_auto">2-Press "Put your product On Front Page"
                            corresponding to the needed Online products</p>
                        <p class="text_align_center put_margin_not_auto">Note: Single products can be put on one or more
                            than one Front Page Time slot so your product can be seen on the front page as much as
                            you can</p>
                    </div>
                    <div class="row" (click)="focusout()" style="direction: rtl;">
                        <div class="col-lg-4">
                            <h1 *ngIf="this.selected_frontpage_ts_id != 0"
                                class="text_align_center put_margin_not_auto">
                                Products On TheNileZone Front Page in the selected Time Slot</h1>
                            <p *ngIf="this.selected_frontpage_ts_id != 0" class="text_align_center put_margin_not_auto">
                                Here are the Products On TheNileZone Front Page (in the selected time slot).</p>
                        </div>
                        <div class="col-lg-4">
                            <h1 class="text_align_center put_margin_not_auto">Availiable Front Page Time Slots</h1>
                        </div>
                        <div class="col-lg-4">
                            <h1 class="text_align_center put_margin_not_auto">Online Products</h1>
                            <p class="text_align_center put_margin_not_auto">The product must be online on your company
                                page
                                before you can see it below and be able to put it on the front page</p>
                        </div>
                    </div>
                    <div class="row" style="direction: rtl;">
                        <div class="col-lg-4">
                            <div *ngIf="this.selected_frontpage_ts_id != 0">
                                <div class="put_margin put_border row" *ngFor="let product of filter_frontpage_products()">
                                    <div class="col-lg-6">
                                        <img src="{{Pictures_Url + 'Product_Pictures/' + product.company_Id + '/' + product.branch_Id + '/' + product.product_Pic_path}}"
                                            style="max-width: 100%" />
                                    </div>
                                    <div class="col-lg-6">
                                        <button class="btn btn-primary" (click)="remove_product_frontpage(product.product_Id , product.product_Id_Date)">Remove your
                                            Product from the Front
                                            Page >></button>
                                    </div>
                                    <h3><strong>{{product.product_Name}}</strong> </h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 text_align_center" (focusout)="focusout_onair()">
                            <div class="put_margin put_border row "
                                [ngClass]="{'slot_select' : (selected_frontpage_ts_id === product.point_Consumbtion_Id && selected_frontpage_ts_id_date === product.point_Consumbtion_Id_Date)}"
                                (click)="select_frontpage_ts(product.point_Consumbtion_Id,product.point_Consumbtion_Id_Date)"
                                *ngFor="let product of filterItemsOfType(true)">
                                <h3><strong>Start
                                        Date</strong>{{this.datePipe.transform(product.point_Consumbtion_Start_Date,
                                    'yyyy-MM-dd')}}</h3>
                                <h3><strong>End
                                        Date</strong>{{this.datePipe.transform(product.point_Consumbtion_End_Date,
                                    'yyyy-MM-dd')}}</h3>
                                <h3><strong>Not Used availiable
                                        Slots</strong>{{product.point_Consumbtion_No_Time_Slots_Active_Not_Used}}</h3>
                                <h3><strong>Slots Weight</strong>{{product.point_Consumbtion_No_Of_Time_Slots}}</h3>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="put_margin put_border row" *ngFor="let product of online_products">
                                <div class="col-lg-6">
                                    <p *ngIf="check_if_product_onfrontpage(product.product_Id , product.product_Id_Date)">Product is already on this Time Slot</p>
                                    <button *ngIf="!check_if_product_onfrontpage(product.product_Id , product.product_Id_Date)" class="btn btn-primary" (click)="put_product_frontpage(product.product_Id , product.product_Id_Date)">
                                        << Put your Product on the Front Page</button>
                                </div>
                                <div class="col-lg-6">
                                    <img src="{{Pictures_Url + 'Product_Pictures/' + product.company_Id + '/' + product.branch_Id + '/' + product.product_Pic_path}}"
                                        style="max-width: 100%" />
                                </div>
                                <h3><strong>{{product.product_Name}}</strong> </h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</section>