<div   *ngIf="language_param == 2">
    <div class="row top-bar" id="vid">
        <div class="col-lg-8 col-md-8 col-12 d-flex align-items-center justify-content-lg-start justify-content-md-around right-items">
            <div class="margin_right_15">
                <a href="https://www.facebook.com/thenilezone.shops/" target="_blank">
                    <img src="../../../assets/images/social/icons8-facebook.svg" />
                </a>
            </div>
            <div class="d-flex align-items-center">
                <p class="mr-2 p-0 m-0">Language</p>
                <select name="" (change)="lang($event.target.value)" class="txt-box" [value]='2'>
                    <option value="1">Arabic</option>
                    <option value="2">English</option>
                </select>
            </div>
        </div>
        <div class="col-lg-4 col-md-4 col-12 d-flex align-items-center justify-content-lg-end justify-content-md-center left-links pl-0">
            <ul>
                <li> <a routerLink='/out/sign-in' [routerLinkActive]="['active']">Sign In</a></li>                
                <li> <a href="http://www.thenilezone.com/general/register/" target="_blank">Register</a></li>                
            </ul>
        </div>
    </div>
    <div class="header-row" id="floatingheader">
        <div class="row">
            <div class="col-lg-3 col-md-12 col-sm-12 logo pl-0" style="width: auto;">
                <img src="{{Pictures_Url + 'logo.webp'}}" alt="Logo" routerLink='/home' />
            </div>
            <div class="col-lg-9 col-md-12 col-sm-12 menu">
                <ul>
                    <li><a href="http://www.thenilezone.com/general/about-us" target="_blank">About Us</a></li>
                    <li><a href="http://www.thenilezone.com/general/contact-us" target="_blank">Contact Us</a></li>
                </ul>
            </div>
        </div>
    </div>
    <div *ngIf="showscroll" class="scroll-btn" (click)="scrollTop()">
        <span class="material-icons">keyboard_arrow_up</span>
    </div>
</div>


<div *ngIf="language_param == 1" style="direction: rtl;">
    <div class="row top-bar" id="vid">
        <div class="col-lg-8 col-md-8 col-12 d-flex align-items-center justify-content-lg-start justify-content-md-around right-items">
            <div class="margin_right_15">
                <a href="https://www.facebook.com/thenilezone.shops/" target="_blank">
                    <img src="../../../assets/images/social/icons8-facebook.svg" />
                </a>
            </div>
            <div class="d-flex margin_right_15">
                <p class="mr-2 p-0 m-0">اللغة: </p>
                <select name="" (change)="lang($event.target.value)" class="txt-box" [value]='1'>
                    <option value="1">عربي</option>
                    <option value="2">أنجليزي</option>
                </select>
            </div>
        </div>
        <div class="col-lg-4 col-md-4 col-12 d-flex align-items-center justify-content-lg-end justify-content-md-center left-links pl-0">
            <ul>
                <li> <a routerLink='/ar/out/sign-in' [routerLinkActive]="['active']">Sign In</a></li>                
                <li> <a href="http://www.thenilezone.com/general/register/" target="_blank">Register</a></li>                
            </ul>
        </div>
    </div>
    <div class="header-row" id="floatingheader">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-12 logo pl-0">
                <img src="../../../assets/images/logo.webp" alt="Logo" routerLink='/home' />
            </div>
        </div>
    </div>
    <div *ngIf="showscroll" class="scroll-btn" (click)="scrollTop()">
        <span class="material-icons">keyboard_arrow_up</span>
    </div>
</div>
<router-outlet></router-outlet>