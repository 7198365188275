<div class="big_Container row">
    <div class="col-lg-11 col-sm-11 text_align_center">
        <h2>Extend Front Page Slot</h2>
        <p>Front Page Slots enables you to put your Products on TheNileZone home page so people can see your product once they open the website home page</p>
        <p>Slot Weight is the number of times your product appear on the front page</p>
    </div>
    <div class="dialog_confirm_content_container col-lg-1 col-sm-1">
        <span class="material-icons" id="close-icon" (click)="cancel()">close</span>
    </div>
</div>
<div class="container margin-bottom-50">
    <form [formGroup]="FG_Add_online_TS" class="Medium_Container put_border text_align_center">
        <div class="row ">
            <div class="col-lg-5">
                <label class="General_label  ">Slot Weight </label>
                <label class="General_label  ">(Note: each slot can have five product)</label>
                <label class="General_label color_red">(Note: You can not change slot weight of the existing slots for better calculation but you can add new separate Front Page Time Slots)</label>
            </div>
            <div class="col-lg-5">
                <mat-form-field class="full_width " appearance="fill" color="accent" floatLabel="always">
                    <!-- <input matInput type="number" formControlName="Point_Company_Consumption_No_Of_Time_Slots"
                        (input)="calculate()" autocomplete="off" disabled> -->
                    <input matInput type="number" 
                    value="{{this.FG_Add_online_TS.controls['Point_Company_Consumption_No_Of_Time_Slots'].value}}"
                         disabled autocomplete="off">
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-5">
            </div>
            <div class="col-lg-5">
                *
            </div>
        </div>
        <div class="row">
            <div class="col-lg-5  ">
                <label class="General_label ">Number of Months</label>
            </div>
            <div class="col-lg-5  ">
                <mat-form-field class="full_width" appearance="fill" color="accent" floatLabel="always">
                    <input matInput type="number" formControlName="Point_Company_Consumption_No_months"
                        (input)="calculate()" autocomplete="off">
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-5">
            </div>
            <div class="col-lg-5">
                *
            </div>
        </div>
        <div class="row put_border put_margin">
            <div class="col-lg-5  ">
                <label class="General_label ">Single Slot Price for 5 products ( 
                    from the system)</label>
            </div>
            <div class="col-lg-5  ">
                <mat-form-field class="full_width" appearance="fill" color="accent" floatLabel="always">
                    <input matInput type="number" value="{{this.data.single_product_online_price}}" disabled
                        autocomplete="off">
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-5">
            </div>
            <div class="col-lg-5">
                =
            </div>
        </div>
        <div class="row put_margin" [ngClass]="(this.FG_Add_online_TS.controls['Point_Company_Consumption_No_Of_Points'].value > this.data.max_points_count) ? 'border_red' : 'put_border' ">
            <div class="col-lg-5">
                <label class="General_label">Number of points Needed to add the Slot Time</label>
                <label class="General_label color_red" *ngIf="(this.FG_Add_online_TS.controls['Point_Company_Consumption_No_Of_Points'].value > this.data.max_points_count)">The company does not have enough points</label>
            </div>
            <div class="col-lg-5">
                <mat-form-field class="full_width" appearance="fill" color="accent" floatLabel="always">
                    <input matInput type="number"
                        value="{{this.FG_Add_online_TS.controls['Point_Company_Consumption_No_Of_Points'].value}}"
                        disabled autocomplete="off">
                </mat-form-field>
            </div>
        </div>
        <div class="row put_border border_green put_margin">
            <div class="col-lg-5">
                <label class="General_label ">Total Number of points the company own</label>
            </div>
            <div class="col-lg-5  ">
                <mat-form-field class="full_width" appearance="fill" color="accent" floatLabel="always">
                    <input matInput value="{{this.data.max_points_count}}" disabled autocomplete="off">
                </mat-form-field>
            </div>
        </div>
        <div flex fxLayout="row" fxLayoutAlign="space-between center">
            <button class="btn btn-primary put_margin_not_auto" [disabled]="disable_button" (click)="add(FG_Add_online_TS)">Extend Front Page Slot/s</button>
            <button class="btn btn-secondary put_margin_not_auto float_right" (click)="cancel()">Cancel</button>
        </div>
    </form>
</div>