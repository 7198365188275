<!-- <div flex fxLayout="row" fxLayoutAlign="space-around" class="big_Container">
    <div flex fxLayout="row" fxFlex="90">
        <H4 *ngIf="(data.Product_Color_Id >0)" class="text_align_center">Upload Product Picture</H4>
        <H4 *ngIf="!(data.Product_Color_Id >0)" class="text_align_center">Update Product Picture</H4>
    </div>
    <div flex fxLayout="row" fxFlex="10" class="dialog_confirm_content_container">
        <span class="material-icons" id="close-icon" (click)="cancel()">close</span>
    </div>
</div> -->
<div class="big_Container row">
    <div class="col-lg-10 col-sm-10">
        <h2 *ngIf="(data.If_Update_Picture )">Update Product Picture</h2>
        <h2 *ngIf="!(data.If_Update_Picture)">Add New Product Picture</h2>
    </div>
    <div class="dialog_confirm_content_container col-lg-1 col-sm-1">
        <span class="material-icons" id="close-icon" (click)="cancel()">close</span>
    </div>
</div>
<div class="container">
    <form [formGroup]="formGroup_Product_Pictures" class="Medium_Container put_border" fxLayout="column">
        <div class="put_padding put_margin row">
            <div class="col-lg-6 col-sm-6">
                <img src={{imgSrc}} alt="Please Select Photo to Upload" class="full_width put_border">
            </div>
            <div class="col-lg-5 col-sm-5">
                <div class="put_margin row" *ngIf="!(data.If_Update_Picture )">
                    <div class="col-lg-6 col-sm-5">
                        <label class="General_label">Choose Picture:</label>
                    </div>
                    <div class="col-lg-6 col-sm-5">
                        <input type="file" accept="image/*" (change)="showPreview($event)" class="General_label"
                            #Caption required>
                    </div>
                </div>
            </div>
        </div>

        <div class="put_margin row">
            <div class="col-lg-6 col-sm-5">
            </div>
            <div class="col-lg-6 col-sm-5">
                <mat-error *ngIf="formGroup_Product_Pictures.controls['Dummy_Has_Photo'].errors">
                    Pleases select Picture</mat-error>
            </div>
        </div>

        <div class="required row" *ngIf="data.If_Update_Picture !=0">
            <div class="col-6">
                <label class="float_right General_label margin_top_right_15">Product Picture Order</label>
            </div>
            <div class="col-lg-6">
                <mat-form-field appearance="fill" color="accent" floatLabel="always">
                    <input matInput type="number" min="1" formControlName="Product_Picture_Order">
                </mat-form-field>
            </div>
        </div>
        <div flex fxLayout="row" fxLayoutAlign="space-between center">
            <button *ngIf="data.If_Update_Picture ==0" class="btn btn-primary put_margin_not_auto"
                (click)="add(formGroup_Product_Pictures)">Add Picture</button>
            <button *ngIf="(data.If_Update_Picture )" class="btn btn-danger put_margin " (click)="delete_product(formGroup_Product_Pictures.controls['Product_Picture_Id'].value,formGroup_Product_Pictures.controls['Product_Picture_Id_Date'].value)">
                Delete Picture</button>
            <button *ngIf="data.If_Update_Picture !=0" class="btn btn-primary put_margin_not_auto"
                (click)="edit(formGroup_Product_Pictures)">Update Picture</button>
            <button class="btn btn-secondary put_margin_not_auto" (click)="cancel()">Cancel</button>
        </div>
    </form>
</div>