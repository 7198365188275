<div class="container">
    <div class="big_Container dialog_confirm_content_container text_align_center row">
        <div class="col-lg-10 col-sm-10">
            <h2 class="uppder_case">Company Picture</h2>
        </div>
        <div class="col-lg-1 col-sm-1">
            <span class="material-icons" id="close-icon" (click)="closeDialog()">close</span>
        </div>
    </div>
    <form [formGroup]="formGroup_Company_Picture_upload" class="Medium_Container">
        <H4 class="text_align_center">Upload Picture</H4>
        <div class="put_border put_padding put_margin row">
            <div class="col-lg-6 col-sm-6">
                <img src={{initial_Pic_Name}} alt="Please choose Photo to Upload" class="full_width put_border">
            </div>
            <div class="col-lg-5 col-sm-5">
                <div class="put_margin row">
                    <div class="col-lg-6 col-sm-5">
                        <label class="General_label">Choose Picture:</label>
                    </div>
                    <div class="col-lg-6 col-sm-5">
                        <input 
                            type="file" accept="image/*" (change)="showPreview($event)" class="General_label" #Caption
                            required>
                    </div>
                </div>

                <div>
                    <mat-error *ngIf="formGroup_Company_Picture_upload.controls['Dummy_Has_Photo'].errors">
                        Pleases choose Picture</mat-error>
                </div>

                <div class="put_margin row">
                    <div class="col-lg-6 col-sm-5">
                        <label class="General_label">Picture Name:</label>
                    </div>
                    <div class="col-lg-6 col-sm-5">
                        <mat-form-field fxFlex="65" fxLayoutAlign="start start" appearance="fill" color="accent"
                            floatLabel="always">
                            <input matInput formControlName="Company_Picture_Display_Name" #Pic_Caption>
                            <!-- Pic_Caption.value -->
                            <mat-error
                                *ngIf="formGroup_Company_Picture_upload.controls['Company_Picture_Display_Name'].errors?.required">
                                Please enter Photo Label</mat-error>
                            <mat-error
                                *ngIf="formGroup_Company_Picture_upload.controls['Company_Picture_Display_Name'].errors?.maxlength">
                                Picture Name is 25 character Maxmimum</mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div>
                    <mat-error *ngIf="formGroup_Company_Picture_upload.controls['Dummy_Name_Already_Used'].errors">
                        Picture Name already used</mat-error>
                </div>

                <div class="put_margin row">
                    <div class="col-lg-6 col-sm-5">
                        <label class="General_label">Picture Type:</label>
                    </div>
                    <div class="col-lg-6 col-sm-5">
                        <mat-radio-group (change)="select_pic_cat()">
                            <mat-radio-button class="put_margin" #general value="1" selected>General</mat-radio-button>
                            <mat-radio-button #owner value="2">Owner Identity</mat-radio-button>
                            <mat-radio-button #branch value="3">Company or Branch Identity</mat-radio-button>
                        </mat-radio-group>
                    </div>
                </div>

                <div>
                    <mat-error *ngIf="formGroup_Company_Picture_upload.controls['Dummy_Has_Pic_Type'].errors">
                        Please select Picture Catigory</mat-error>
                </div>

                <div class="put_margin row">
                    <div class="col-lg-6 col-sm-5">
                        <label class="General_label">Picture Comment:</label>
                    </div>
                    <div class="col-lg-6 col-sm-5">
                        <mat-form-field fxFlex="65" fxLayoutAlign="start start" appearance="fill" color="accent"
                            floatLabel="always">
                            <input matInput formControlName="Company_Picture_Comment">
                        </mat-form-field>
                    </div>
                </div>

                <div class="put_margin row">
                    <div class="fcol-lg-6 col-sm-5">
                        <button (click)="upload_photos(formGroup_Company_Picture_upload.value, Caption)"
                            class="btn btn-primary put_margin">Submit</button>
                    </div>
                    <div class="col-lg-6 col-sm-5">
                        <button (click)="closeDialog()" class="btn btn-secondary put_margin">Close</button>
                    </div>
                </div>

            </div>
        </div>
    </form>
</div>