<section>
    <div class="container text_align_center">
        <h1 class="text_align_center put_padding put_margin_not_auto">أدارة النقاط</h1>
        <p>تقدر تتحكم في نقاطك من هنا</p>
        <h3 class="text_align_center put_padding put_margin_not_auto">شركتك</h3>
        <p>هنا تقدر تشوف كل شركاتك المصرح بها من أدارة الموقع</p>
        <div (click)="focusout()" class=" text_align_center">
            <span class="display_inline_block put_margin_not_auto">
                <mat-form-field appearance="fill">
                    <mat-label>شركتك
                    </mat-label>
                    <mat-select  (selectionChange)="copmany_id_change($event.value)" autocomplete="off">
                        <mat-option style="background-color: white;" *ngFor="let data of Company_List" [value]="data.company_id">
                            {{data.company_name_arabic}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </span>
            <span *ngIf="this.Company_List.length > 1" class="man desc display_inline_block put_margin_not_auto">
                <p>عندك اكتر من شركة. أختار واحدة</p>
            </span>
            <span *ngIf="this.Company_List.length == 0" class="man desc display_inline_block put_margin_not_auto">
                <p>
                    ليس لديك شركة بفرع أنتظر الموافقة علي شركتك من أدارة الموقع أو انشئ شركة من
                    <a routerLink='/main/home' [routerLinkActive]="['active']">هنا</a>
                </p>
            </span>
        </div>
        <form [formGroup]="FG_Branches">
            <div class="put_padding" *ngIf="this.FG_Branches.controls['Company_Id'].value > 0" style="direction: rtl;">
                <div (click)="focusout()" class="container put_margin put_border row full_width">
                    <h1 class=" put_margin_not_auto">تفاصيل شركتك الاساسية</h1>
                    <div class="col-lg-8">
                        <h3><strong>أسم شركتك: </strong> {{this.FG_Branches.controls['Company_Name'].value}}</h3>
                        <h3><strong>حالة الشركة: </strong> {{this.FG_Branches.controls['Company_Status'].value}}</h3>
                        <h3><strong>عدد نقاط الشركة: </strong> {{this.FG_Branches.controls['Company_Points'].value}}</h3>
                        <h3>
                            <strong>
                                عدد المنتجات علي الصفحة الرئيسية:
                            </strong>
                            {{this.FG_Branches.controls['Company_No_of_Products_on_front_page'].value}}
                        </h3>
                        <h3><strong>
                            عدد منتجات الشركة المعروضة:
                        </strong>
                            {{this.FG_Branches.controls['Company_No_of_Online_Products'].value}}</h3>
                        <h3><strong>
                            عدد المنتجات المصرح بها: </strong>
                            {{this.FG_Branches.controls['Company_No_of_approved_Products'].value}}</h3>
                        <h3><strong>عدد المنتجات المرفوضة: </strong>
                            {{this.FG_Branches.controls['Company_No_of_pending_Products'].value}}</h3>
                        <h3><strong>عدد المنتجات المنتظرة الموافقة: </strong>
                            {{this.FG_Branches.controls['Company_No_of_denied_Products'].value}}</h3>
                        <!-- <h3><strong>Company Name: </strong> {{this.FG_Branches.controls['Company_logo_path'].value}} 
                        </h3>-->
                    </div>
                    <div class="col-lg-3 put_margin ">
                        <img src="{{Pictures_Url + 'Companies_Id/' + this.FG_Branches.controls['Company_Id'].value + '/'+ this.FG_Branches.controls['Company_logo_path'].value}}"
                            style="max-width: 100%" />
                    </div>
                </div>

                <h1 (click)="focusout()" class="text_align_center put_margin_not_auto">أدارة النقاط</h1>
                <div (click)="focusout()" class="container put_margin put_border row full_width">
                    <div class="text_align_center">
                        <h1 class="put_margin_not_auto">شرايح المنتجات المعروضة</h1>
                        <p class="put_margin">
                            ببساطة احجز شريحة لتعرض عليها منتجاتك
                        </p>
                        <p class="put_margin">
                            شرايح المنتجات المعروضة ممكن استخدامها لعرض منتجات مختلفة. بمعني 
                            انك ممكن تحذف منتج من شريحة عرض المنتجات وتحدد منتج اخر علي هذة الشريحة مكان المنتج الاول في اي وقت.
                        </p>
                        <div class="put_margin put_border row " *ngFor="let product of filterItemsOfType(false)">
                            <div class="col-lg-4 ">
                                <!-- <button class="btn btn-primary put_margin" (click)="signin()">Decrease Time Slot
                                    Period</button> -->
                            </div>
                            <div class="col-lg-4">
                                <h3><strong>
                                    تاريخ البداية
                                </strong>{{this.datePipe.transform(product.point_Consumbtion_Start_Date,
                                    'yyyy-MM-dd')}}</h3>
                                <h3><strong>
                                    تاريخ النهاية
                                </strong>{{this.datePipe.transform(product.point_Consumbtion_End_Date,
                                    'yyyy-MM-dd')}}</h3>
                                <h3><strong>
                                    عدد اماكن المنتجات الغير مستغلة
                                </strong>{{product.point_Consumbtion_No_Time_Slots_Active_Not_Used}}</h3>
                                <h3><strong>
                                    العدد الكلي لاماكن المنتجات
                                </strong>{{product.point_Consumbtion_No_Of_Time_Slots}}</h3>
                            </div>
                            <div class="col-lg-4 text_align_center">
                                <button class="btn btn-primary put_margin"
                                    (click)="extend_online_ts(product.point_Consumbtion_Id,product.point_Consumbtion_Id_Date, product.point_Consumbtion_No_Of_Time_Slots)">
                                    زيادة وقت الشريحة
                                </button>
                                <p class="put_margin">
                                    يمكنك زيادة الوقت لهذة الشريحة
                                </p>
                                <p class="put_margin">
                                    (ديه طريقة اسهل من فتح شريحة جديدة لعرض منتجات لانك هتحتاج تحدد المنتجات 
                                    المعروضة مرة تانية)
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="put_padding row">
                        <div class="col-lg-4">
                            <h3><strong>عدد نقاط الشركة: </strong> {{this.FG_Branches.controls['Company_Points'].value}}
                            </h3>
                        </div>
                        <div class="col-lg-4">
                            <button class="btn btn-primary put_margin" (click)="add_online_ts()">
                                أضافة شريحة لعرض المنتجات
                            </button>
                        </div>
                        <div class="col-lg-4">
                            <p class="put_margin">
                                (أضف شريحة بغرض عرض المنتجات)
                            </p>
                        </div>
                    </div>
                </div>
                <div (click)="focusout()" class="container put_margin put_border row full_width" >
                    <div class="text_align_center" >
                        <h1 class="put_margin_not_auto">
                            شرايح عرض المنتجات علي الصفحة الرئيسية
                        </h1>
                        <p class="put_margin">
                            أحصل ببساطة علي شريحة لتضع منتجاتك علي الصفحة الرئيسية لموقع TheNileZone
                        </p>
                        <p class="put_margin">
                            يمكن اعادة استخدام شريحة الصفحة الرئيسية لوضع منتجات مختلفة.
                            بمعني انه يمكنك أزاله منتج من الصفحة الرئيسية ووضع منتج اخر اي وقت مناسب لك.                            
                        </p>
                        <div class="put_margin put_border row" *ngFor="let product of filterItemsOfType(true)">
                            <div class="col-lg-4">
                                <!-- <button class="btn btn-primary put_margin" (click)="signin()">Decrease Time Slot
                                    Period</button> -->
                            </div>
                            <div class="col-lg-4">
                                <h3><strong>
                                    تاريخ البداية
                                </strong>{{this.datePipe.transform(product.point_Consumbtion_Start_Date,
                                    'yyyy-MM-dd')}}</h3>
                                <h3><strong>
                                    تاريخ النهاية
                                </strong>{{this.datePipe.transform(product.point_Consumbtion_End_Date,
                                    'yyyy-MM-dd')}}</h3>
                                <h3><strong>
                                    عدد الاماكن المتاحة علي الشريحة
                                    </strong>{{product.point_Consumbtion_No_Time_Slots_Active_Not_Used}}</h3>
                                <h3><strong>
                                    وزن الشريحة علي الصفحة الرئيسية
                                </strong>{{product.point_Consumbtion_No_Of_Time_Slots}}
                                </h3>
                            </div>
                            <div class="col-lg-4 text_align_center">
                                <button class="btn btn-primary put_margin"
                                    (click)="extend_frontpage_ts(product.point_Consumbtion_Id,product.point_Consumbtion_Id_Date, product.point_Consumbtion_No_Of_Time_Slots)">
                                    زيادة وقت الشريحة
                                </button>
                                <p class="put_margin">
                                    زيادة الوقت للشريحة الموجودة
                                </p>
                                <p class="put_margin">                                    
                                    (ديه طريقة اسهل من فتح شريحة جديدة لعرض منتجات لانك هتحتاج تحدد المنتجات 
                                    علي الصفحة الرئيسية مرة تانية)
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="put_padding row">
                        <div class="col-lg-4 ">
                            <h3><strong>عدد نقاط الشركة: </strong> {{this.FG_Branches.controls['Company_Points'].value}}
                            </h3>
                        </div>
                        <div class="col-lg-4 ">
                            <button class="btn btn-primary put_margin" (click)="add_frontpage_ts()">
                                أضافة شريحة للصفحة الرئيسية
                            </button>
                        </div>
                        <div class="col-lg-4">
                            <p class="put_margin">
                                (أضف شريحة بغرض عرض المنتجات علي صفحة TheNileZone الرئيسية)
                            </p>
                        </div>
                    </div>
                </div>

                <div class="container put_margin put_border row full_width" style="direction: ltr;">
                    <div (click)="focusout()">
                        <h1 class="text_align_center put_margin_not_auto">
                            أعرض منتجك علي المنصة
                        </h1>
                        <p class="text_align_center put_margin_not_auto">
                            خطوات عرض المنتج:
                        </p>
                        <p class="text_align_center put_margin_not_auto">
                            1- أختار شريحة عرض منتجات
                        </p>
                        <p class="text_align_center put_margin_not_auto">
                            2- أضغط علي زر "أعرض المنتج" من علي صورة المنتج المصرح به
                        </p>
                        <br>
                    </div>
                    <div (click)="focusout()" class="row">
                        <div class="col-lg-4">
                            <h1 *ngIf="this.selected_online_ts_id != 0" class="text_align_center put_margin_not_auto">
                                المنتجات المعروضة علي الشريحة المختارة
                            </h1>
                            <p *ngIf="this.selected_online_ts_id != 0" class="text_align_center put_margin_not_auto">
                                من هنا تقدر تضع منتجك (علي الشريحة المختارة)
                            </p>
                        </div>
                        <div class="col-lg-4">
                            <h1 class="text_align_center put_margin_not_auto">
                                شرايح عرض المنتجات المتاحة
                            </h1>
                            <p class="text_align_center put_margin_not_auto">
                                أختار شريحة عرض منتجات واحدة
                            </p>
                        </div>
                        <div class="col-lg-4">
                            <h1 class="text_align_center put_margin_not_auto">
                                منتجات مصرح بها لكن غير معروضة
                            </h1>
                            <p class="text_align_center put_margin_not_auto">
                                لازم يكون المنتج مصرح به من أدارة الموقع الاول 
                                قبل انك تشوفه هنا وتقدر تعرض منتجك علي صفحة شركتك                                
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-4">
                            <div *ngIf="this.selected_online_ts_id != 0">
                                <div class="put_margin put_border row" *ngFor="let product of filter_online_products()">
                                    <!-- <div class="col-lg-4">
                                    <button class="btn btn-primary" (click)="put_product_online()">
                                        << Put your Product Online</button>
                                </div> -->
                                    <div class="col-lg-6">
                                        <img src="{{Pictures_Url + 'Product_Pictures/' + product.company_Id + '/' + product.branch_Id + '/' + product.product_Pic_path}}"
                                            style="max-width: 100%" />
                                        <h3><strong>{{product.product_Name}}</strong> </h3>
                                    </div>
                                    <div class="col-lg-6">
                                        <button class="btn btn-primary"
                                            (click)="put_product_offline(product.product_Id , product.product_Id_Date)">
                                            ازالة منتجك من الشريحة\العرض
                                            >></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 text_align_center" (focusout)="focusout_onair()">
                            <div class="put_margin put_border row"
                                [ngClass]="{'slot_select' : (selected_online_ts_id === product.point_Consumbtion_Id && selected_online_ts_id_date === product.point_Consumbtion_Id_Date)}"
                                (click)="select_online_ts(product.point_Consumbtion_Id,product.point_Consumbtion_Id_Date)"
                                *ngFor="let product of filterItemsOfType(false)">
                                <h3><strong>
                                    تاريخ البداية: </strong>{{this.datePipe.transform(product.point_Consumbtion_Start_Date,
                                    'yyyy-MM-dd')}}</h3>
                                <h3><strong>
                                    تاريخ النهاية: </strong>{{this.datePipe.transform(product.point_Consumbtion_End_Date,
                                    'yyyy-MM-dd')}}</h3>
                                <h3><strong>
                                    اماكن غير مستغلة علي الشريحة
                                    : </strong>{{product.point_Consumbtion_No_Time_Slots_Active_Not_Used}}</h3>
                                <h3><strong>
                                    عدد كل الاماكن
                                    : </strong>{{product.point_Consumbtion_No_Of_Time_Slots}}</h3>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="put_margin put_border row" *ngFor="let product of approved_products">
                                <div class="col-lg-6">
                                    <button class="btn btn-primary"
                                        (click)="put_product_online(product.product_Id , product.product_Id_Date)">
                                        أعرض منتجك <<</button>
                                </div>
                                <div class="col-lg-6">
                                    <img src="{{Pictures_Url + 'Product_Pictures/' + product.company_Id + '/' + product.branch_Id + '/' + product.product_Pic_path}}"
                                        style="max-width: 100%" />
                                    <h3><strong>{{product.product_Name}}</strong> </h3>
                                </div>
                                <!-- <div class="col-lg-4">
                                    <button class="btn btn-primary" (click)="put_product_offline()">Put your Product offline
                                        >></button>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container put_margin put_padding put_border row full_width" style="direction: ltr;">
                    <div (click)="focusout()">
                        <h1 class="text_align_center put_margin_not_auto">
                            ضع منتجاتك علي الصفحة الرئيسية
                        </h1>
                        <p class="text_align_center put_margin_not_auto">
                            الخطوات:
                        </p>
                        <p class="text_align_center put_margin_not_auto">
                            1- أختار شريحة لعرض منتجك علي الصفحة الرئيسية
                        </p>
                        <p class="text_align_center put_margin_not_auto">
                            2- أضغط علي "ضع منتجك علي الصفحة الرئيسية" من صورة المنتج
                        </p>
                        <p class="text_align_center put_margin_not_auto">
                            ملحوظة: المنتج الواحد يمكن وضعه علي اكثر من شريحة صفحة رئيسية
                            لزيادة وقت عرض المنتج علي الصفحة الرئيسية.
                        </p>
                    </div>
                    <div class="row" (click)="focusout()">
                        <div class="col-lg-4">
                            <h1 *ngIf="this.selected_frontpage_ts_id != 0"
                                class="text_align_center put_margin_not_auto">
                                منتجات علي صفحة TheNileZone الرئيسية علي الشريحة المختارة
                            </h1>
                            <p *ngIf="this.selected_frontpage_ts_id != 0" class="text_align_center put_margin_not_auto">
                                هنا المنتجات المعروضة علي صفحة TheNileZone الرئيسية (علي الشريحة المختارة).
                            </p>
                        </div>
                        <div class="col-lg-4">
                            <h1 class="text_align_center put_margin_not_auto">
                                شرايح العرض علي صفحة TheNileZone الرئيسية.
                            </h1>
                        </div>
                        <div class="col-lg-4">
                            <h1 class="text_align_center put_margin_not_auto">
                                منتجات معروضة علي صفحة الشركة
                            </h1>
                            <p class="text_align_center put_margin_not_auto">
                                لازم المنتج يكون معروض علي صفحة شركتك فتقدر تشوفه هنا
                                وتعينه علي شريحة صفحة TheNileZone الرئيسية.
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-4">
                            <div *ngIf="this.selected_frontpage_ts_id != 0">
                                <div class="put_margin put_border row"
                                    *ngFor="let product of filter_frontpage_products()">
                                    <div class="col-lg-6">
                                        <img src="{{Pictures_Url + 'Product_Pictures/' + product.company_Id + '/' + product.branch_Id + '/' + product.product_Pic_path}}"
                                            style="max-width: 100%" />
                                    </div>
                                    <div class="col-lg-6">
                                        <button class="btn btn-primary"
                                            (click)="remove_product_frontpage(product.product_Id , product.product_Id_Date)">
                                            أرفع منتجك من الصفحة الرئيسية >>
                                        </button>
                                    </div>
                                    <h3><strong>{{product.product_Name}}</strong> </h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 text_align_center" (focusout)="focusout_onair()">
                            <div class="put_margin put_border row "
                                [ngClass]="{'slot_select' : (selected_frontpage_ts_id === product.point_Consumbtion_Id && selected_frontpage_ts_id_date === product.point_Consumbtion_Id_Date)}"
                                (click)="select_frontpage_ts(product.point_Consumbtion_Id,product.point_Consumbtion_Id_Date)"
                                *ngFor="let product of filterItemsOfType(true)">
                                <h3><strong>
                                    تاريخ البداية
                                </strong>{{this.datePipe.transform(product.point_Consumbtion_Start_Date,
                                    'yyyy-MM-dd')}}</h3>
                                <h3><strong>
                                    تاريخ النهاية
                                    </strong>{{this.datePipe.transform(product.point_Consumbtion_End_Date,
                                    'yyyy-MM-dd')}}</h3>
                                <h3><strong>
                                    عدد الاماكن المتاحة علي الشريحة
                                    </strong>{{product.point_Consumbtion_No_Time_Slots_Active_Not_Used}}</h3>
                                <h3><strong>
                                    وزن الشريحة
                                </strong>{{product.point_Consumbtion_No_Of_Time_Slots}}</h3>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="put_margin put_border row" *ngFor="let product of online_products">
                                <div class="col-lg-6">
                                    <p *ngIf="check_if_product_onfrontpage(product.product_Id , product.product_Id_Date)">
                                        منتجك علي هذة الشريحة
                                    </p>
                                    <button
                                        *ngIf="!check_if_product_onfrontpage(product.product_Id , product.product_Id_Date)"
                                        class="btn btn-primary"
                                        (click)="put_product_frontpage(product.product_Id , product.product_Id_Date)">
                                        ضع منتجك علي الصفحة الرئيسية 
                                        <<
                                </button>
                                </div>
                                <div class="col-lg-6">
                                    <img src="{{Pictures_Url + 'Product_Pictures/' + product.company_Id + '/' + product.branch_Id + '/' + product.product_Pic_path}}"
                                        style="max-width: 100%" />
                                </div>
                                <h3><strong>{{product.product_Name}}</strong> </h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</section>