<section>
    <div class="container" style="direction: rtl;">
        <div class="text_align_center">
            <h1 class="put_margin_not_auto">شركتك\شركاتك</h1>
            <p>هنا تقدر تشوف كل شركاتك.</p>
            <p>شركاتك ممكن تكون تحت المراجعة او تم الموافقة عليها عن طريق فريق عمل TheNileZone.</p>
        </div>
        <div class="row put_border put_margin" *ngFor="let company of companies">
            <div class="col">
                <div class="col">
                    <div>
                        <div class="row">
                            <div class="col-lg-9">
                                <div class="man desc">
                                    <h3><strong>أسم شركتك: </strong> {{company.company_Name_Arabic}}</h3>
                                    <h3><strong>أسم شركتك باللغة النجليزية: </strong> {{company.company_Name}}</h3>
                                    <h3><strong>حالة الشركة: </strong> {{company.company_Statuses_name}}</h3>
                                    <h3><strong>عدد المنتجات علي صفحة TheNileZone الرئيسية: </strong>
                                        {{company.company_No_of_Products_on_front_page}}</h3>
                                    <h3><strong>عدد المنتجات المعروضة علي صفحة الشركة: </strong>
                                        {{company.company_No_of_Online_Products}}
                                    </h3>
                                    <h3><strong>عدد المنتجات المقبولة وقابلة للعرض: </strong>
                                        {{company.company_No_of_approved_Products}}
                                    </h3>
                                    <h3><strong>عدد المنتجات تحت مراجعة أدارة موقع TheNileZone: </strong>
                                        {{company.company_No_of_pending_Products}}
                                    </h3>
                                    <h3><strong>عدد المنتجات الغير مقبولة: </strong>
                                        {{company.company_No_of_denied_Products}}
                                    </h3>
                                </div>
                            </div>
                            <div class="col-lg-2 put_margin">
                                <img src="{{Pictures_Url + 'Companies_Id/' + company.company_Id + '/' + company.company_logo_path}}"
                                    *ngIf="(company.company_logo_path)" style="max-width: 100%" />

                                <img src="{{Pictures_Url + 'Companies_Id/brand10_resized.webp'}}"
                                    *ngIf="!(company.company_logo_path)" style="max-width: 100%;" />
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="row put_margin">
                            <div class="">
                                <button class="btn btn-primary put_margin "
                                    (click)="dialog_update_company(company.company_Id)">
                                    تحديث بيانات شركتك</button>
                                <button class="btn btn-primary put_margin "
                                    (click)="dialog_view_company(company.company_Id)">
                                    رؤية بيانات شركتك</button>
                                <button class="btn btn-primary put_margin "
                                    (click)="dialog_add_company_photo(company.company_Id)">
                                    أضافة صورة لشركتك</button>
                                <button class="btn btn-primary put_margin "
                                    (click)="dialog_view_company_photos(company.company_Id)">
                                    رؤية صور االشركة</button>
                                <button class="btn btn-danger put_margin "
                                    (click)="dialog_delete_company(company.company_Id)">
                                    حذف الشركة</button>
                            </div>
                        </div>
                    </div>
                </div>
                <h1 class="text_align_center put_margin_not_auto">Your Branch/es</h1>
                <div class=" container put_border put_margin" *ngFor="let branch of company.branches">
                    <div class="man desc">
                        <h3><strong>أسم الفرع: </strong> {{branch.branch_Name_Arabic}}</h3>
                        <h3><strong>أسم الفرع باللغة الانجليزية: </strong> {{branch.branch_Name}}</h3>
                        <h3><strong>حالة الفرع: </strong> {{branch.branch_status_name}}</h3>
                    </div>
                    <div class="row put_margin">
                        <div class="">
                            <button class="btn btn-primary put_margin float_right"
                            (click)="dialog_view_branch(branch.branch_ID)">
                            رؤية تفاصيل الفرع</button>
                            <button class="btn btn-primary put_margin float_right"
                            (click)="dialog_update_branch(branch.branch_ID)">
                            تحديث بيانات الفرع</button>
                            <button class="btn btn-danger put_margin float_right"
                                (click)="dialog_delete_branch(branch.branch_ID)">
                                حذف الفرع</button>
                        </div>
                    </div>
                </div>
                <div class="row put_margin float_right">
                    <div class="">
                        <button class="btn btn-primary put_margin" (click)="dialog_create_branch(company.company_Id)">
                            أنشئ فرع جديد</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="row put_margin">
            <div>
                <button class="btn btn-primary put_margin" (click)="dialog_create_company()">
                    أنشئ شركة</button>
            </div>
        </div>
    </div>