<section >
    <div class="container" style="direction: rtl;">
        <h1 class="put_margin">أفرع شركتك</h1>
        <div class="row put_border put_margin" *ngFor="let company of branches" >
            <div class="col-lg-12">
                <div class="man desc">
                    <h3><strong>أسم الشركة: </strong> {{company.company_name}}</h3>
                    <h3><strong>أسم الفرع: </strong> {{company.branch_Name_Arabic}}</h3>
                    <h3><strong>أسم الفرع باللغة الانجليزية: </strong> {{company.branch_name}}</h3>
                    <h3><strong>أسم المنطقة: </strong> {{company.areas_name}}</h3>                    
                </div>
            </div>
        </div>
    </div>
</section>