<div class="container" flex fxLayout="column">
    <H2>Add Product to Branch</H2>
    <form [formGroup]="formGroup_insert_Archived_Products" class="Medium_Container">
        <div class="put_border put_padding put_margin">
            <H4 class="text_align_center">Product Data</H4>
            <div class="required row ">
                <div class="col-6">
                    <label class="float_right General_label margin_top_right_15">Product Name</label>
                </div>
                <div class="col-6">
                    <mat-form-field class="full_width" appearance="fill" color="accent" floatLabel="always">
                        <input matInput formControlName="Archived_Product_Name">
                        <mat-error
                            *ngIf="formGroup_insert_Archived_Products.controls['Archived_Product_Name'].errors?.required">This
                            field is Mandatory</mat-error>
                        <mat-error *ngIf="
                        formGroup_insert_Archived_Products.controls['Archived_Product_Name'].errors?.maxlength">Maximum
                            length is
                            50character</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="required row ">
                <div class="col-6">
                    <label class="float_right General_label margin_top_right_15">Product Name Arabic</label>
                </div>
                <div class="col-6">
                    <mat-form-field class="full_width" appearance="fill" color="accent" floatLabel="always">
                        <input matInput formControlName="Archived_Product_Name_Arabic" #arabic_name>
                        <mat-error
                            *ngIf="formGroup_insert_Archived_Products.controls['Archived_Product_Name_Arabic'].errors?.required">This
                            field is Mandatory</mat-error>
                        <mat-error
                            *ngIf="
                        formGroup_insert_Archived_Products.controls['Archived_Product_Name_Arabic'].errors?.maxlength">Maximum
                            length is
                            50character</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div flex fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around">
                <div fxFlex="30" class="General_label" fxLayoutAlign="end center" fxLayoutAlign.lt-sm="start center">
                    <label class="General_label" fxFlex="100" fxLayoutAlign="end center"
                        fxLayoutAlign.lt-sm="start center">Product Name and Product Arabic Name could not be
                        repeated in the same branch</label>
                </div>
            </div>

            <div class="required row ">
                <div class="col-6">
                    <label class="float_right General_label margin_top_right_15">Product Description</label>
                </div>
                <div class="col-6">
                    <mat-form-field class="full_width" appearance="fill" color="accent" floatLabel="always">
                        <input matInput formControlName="Archived_Product_Description">
                        <mat-error
                            *ngIf="formGroup_insert_Archived_Products.controls['Archived_Product_Description'].errors?.required">This
                            field is Mandatory</mat-error>
                        <mat-error
                            *ngIf="
                    formGroup_insert_Archived_Products.controls['Archived_Product_Description'].errors?.maxlength">Maximum
                            length is
                            50character</mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="required row ">
                <div class="col-6">
                    <label class="float_right General_label margin_top_right_15">Product Description Arabic</label>
                </div>
                <div class="col-6">
                    <mat-form-field class="full_width" appearance="fill" color="accent" floatLabel="always">
                        <input matInput formControlName="Archived_Product_Description_Arabic">
                        <mat-error
                            *ngIf="formGroup_insert_Archived_Products.controls['Archived_Product_Description_Arabic'].errors?.required">This
                            field is Mandatory</mat-error>
                        <mat-error
                            *ngIf="
                formGroup_insert_Archived_Products.controls['Archived_Product_Description_Arabic'].errors?.maxlength">Maximum
                            length is
                            50character</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="required row ">
                <div class="col-6">
                    <label class="float_right General_label margin_top_right_15">Catigory 1 Name</label>
                </div>
                <div class="col-6">
                    <mat-form-field appearance="fill" color="accent" floatLabel="always">
                        <mat-select formControlName="cat_1"
                        (selectionChange)="cat_1_change($event)">
                            <mat-option style="background-color: white;" [value]="Cat_1_list_item.product_Catigory_1_Id"
                                *ngFor="let Cat_1_list_item of Cat_1_list">{{Cat_1_list_item.product_Catigory_1_Name}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="formGroup_insert_Archived_Products.controls['cat_1'].errors">This
                            field is Mandatory</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="required row ">
                <div class="col-6">
                    <label class="float_right General_label margin_top_right_15">Catigory 2 Name</label>
                </div>
                <div class="col-6">
                    <mat-form-field appearance="fill" color="accent" floatLabel="always">
                        <mat-select formControlName="cat_2"
                        (selectionChange)="cat_2_change($event)">
                            <mat-option style="background-color: white;" [value]="Cat_2_list_item.product_Catigory_2_Id"
                                *ngFor="let Cat_2_list_item of Cat_2_list">{{Cat_2_list_item.product_Catigory_2_Name}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="formGroup_insert_Archived_Products.controls['cat_2'].errors">This
                            field is Mandatory</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="required row ">
                <div class="col-6">
                    <label class="float_right General_label margin_top_right_15">Catigory 3 Name</label>
                </div>
                <div class="col-6">
                    <mat-form-field appearance="fill" color="accent" floatLabel="always">
                        <mat-select formControlName="cat_3" 
                        (selectionChange)="cat_3_change($event)">
                            <mat-option style="background-color: white;" [value]="Cat_3_list_item.product_Catigory_3_Id"
                                *ngFor="let Cat_3_list_item of Cat_3_list">{{Cat_3_list_item.product_Catigory_3_Name}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="formGroup_insert_Archived_Products.controls['cat_3'].errors">This
                            field is Mandatory</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="required row ">
                <div class="col-6">
                    <label class="float_right General_label margin_top_right_15">Catigory 4 Name</label>
                </div>
                <div class="col-6">
                    <mat-form-field appearance="fill" color="accent" floatLabel="always">
                        <mat-select formControlName="Archived_Product_Related_Catigory_4">
                            <mat-option style="background-color: white;" [value]="Cat_4_list_item.product_Catigory_4_Id"
                                *ngFor="let Cat_4_list_item of Cat_4_list">{{Cat_4_list_item.product_Catigory_4_Name}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="formGroup_insert_Archived_Products.controls['Archived_Product_Related_Catigory_4'].errors">This
                            field is Mandatory</mat-error>
                    </mat-form-field>
                </div>
            </div>
            
            <div class="required row ">
                <div class="col-6">
                    <label class="float_right General_label margin_top_right_15">Product Price</label>
                </div>
                <div class="col-6">
                    <mat-form-field appearance="fill" color="accent" floatLabel="always">
                        <input min="0" type="number" matInput formControlName="Archived_Products_Price">
                        <mat-error
                            *ngIf="formGroup_insert_Archived_Products.controls['Archived_Products_Price'].errors">This
                            field is Mandatory</mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="required row ">
                <div class="col-6">
                    <label class="float_right General_label margin_top_right_15">Product Notes</label>
                </div>
                <div class="col-6">
                    <mat-form-field appearance="fill" color="accent" floatLabel="always">
                        <input matInput formControlName="Archived_Product_Note">
                    </mat-form-field>
                </div>
            </div>

            <div class="required row ">
                <div class="col-6">
                    <label class="float_right General_label margin_top_right_15">Product Delivery Status</label>
                </div>
                <div class="col-6">
                    <mat-form-field appearance="fill" color="accent" floatLabel="always">
                        <mat-select formControlName="Archived_Products_Delivery_Status">
                            <mat-option style="background-color: white;" [value]="item.product_Delivery_Status_Id"
                                *ngFor="let item of prod_delivery_statuses">{{item.product_Delivery_Status_Name}}
                            </mat-option>
                        </mat-select>
                        <mat-error
                            *ngIf="formGroup_insert_Archived_Products.controls['Archived_Products_Delivery_Status'].errors">This
                            field is Mandatory</mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="required row ">
                <div class="col-6">
                    <label class="float_right General_label margin_top_right_15">Needed points for Delivery from the
                        Company</label>
                </div>
                <div class="col-6">
                    <mat-form-field appearance="fill" color="accent" floatLabel="always">
                        <input min="0" matInput type="number" formControlName="Archived_Product_Company_Delivery_Point">
                        <mat-error
                            *ngIf="formGroup_insert_Archived_Products.controls['Archived_Product_Company_Delivery_Point'].errors">This
                            field is Mandatory</mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="required row ">
                <div class="col-6">
                    <label class="float_right General_label margin_top_right_15">Needed points for Delivery from the
                        Customer</label>
                </div>
                <div class="col-6">
                    <mat-form-field appearance="fill" color="accent" floatLabel="always">
                        <input min="0" type="number" matInput formControlName="Archived_Product_User_Delivery_Point">
                        <mat-error
                            *ngIf="formGroup_insert_Archived_Products.controls['Archived_Product_User_Delivery_Point'].errors">This
                            field is Mandatory</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="put_margin text_align_end">
                <div class="">
                    <button class="btn btn-primary " (click)="create_product(formGroup_insert_Archived_Products)">Create
                        Products</button>
                </div>

            </div>
        </div>
    </form>

    <div>
        <div class="float_right">

        </div>

        <div>
            <button class="btn btn-secondary put_margin" routerLink="/main/products_management"
                routerLinkActive='active' class="btn btn-secondary">Back</button>
        </div>

    </div>
</div>