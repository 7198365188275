<div class="header-row" id="floatingheader" style="direction: rtl;">
    <div class="row">
        <div class="col-lg-3 col-md-12 col-sm-12 logo pl-0" style="width: auto;">
            <img src="{{Pictures_Url + 'logo.webp'}}" alt="Logo" routerLink='/home' />
        </div>
        <div class="col-lg-9 col-md-12 col-sm-12 menu">
            <ul>
                <li><a routerLink='/ar/main/home' [routerLinkActive]="['active']">شركتك</a></li>
                <li><a routerLink='/ar/main/points_management' [routerLinkActive]="['active']">أدارة النقط</a></li>
                <li><a routerLink='/ar/main/products_management' [routerLinkActive]="['active']">أدارة المنتجات</a></li>
                <li><a routerLink='/ar/main/my_branches' [routerLinkActive]="['active']">الفروع</a></li>
                <li><a href="http://www.thenilezone.com/ar/general/about-us" target="_blank">من نحن</a></li>
                <li><a href="http://www.thenilezone.com/ar/general/contact-us" target="_blank">أتصل بنا</a></li>
            </ul>
        </div>
    </div>
</div>