<div>
    <div class="dialog_confirm_content_container">
        <!-- <mat-icon id="close-icon" (click)="closeDialog()">close</mat-icon> -->
        <span class="material-icons col-lg-1 " id="close-icon" (click)="closeDialog()">close</span>
        <span class="content-span full-width col-lg-11">{{data.message}}</span>
    </div>
    <div class="dialog_confirm_content_container display_inline_block text_align_right">
        <button class="margin_right_15" mat-flat-button id="no-button" [mat-dialog-close]="false">No</button>
        <button class="margin_right_15" mat-flat-button id="yes-button" [mat-dialog-close]="true">Yes</button>
    </div>
</div>