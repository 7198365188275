<div class="big_Container row" style="direction: rtl;">
    <div class="col-lg-11 col-sm-11 text_align_center">
        <h2>
            زيادة وقت شريحة المنتجات المعروضة
        </h2>
        <p>
            تتيح لك شريحة المنتجات المعروضة عرض منتجاتك علي صفحة شركتك
            فيستطيع الزوار رؤية تفاصيل المنتجات
        </p>
    </div>
    <div class="dialog_confirm_content_container col-lg-1 col-sm-1">
        <span class="material-icons" id="close-icon" (click)="cancel()">close</span>
    </div>
</div>
<div class="container margin-bottom-50">
    <form [formGroup]="FG_Add_online_TS" class="Medium_Container put_border text_align_center" style="direction: rtl;">
        <div class="row put_border put_margin">
            <div class="col-lg-5">
                <label class="General_label">
                    عدد الاماكن علي الشريحة
                </label>
                <label class="General_label  ">
                    (ملحوظة: كل مكان يحمل منتج واحد فقط)
                </label>
                <label class="General_label color_red">
                    (ملحوظة: لا يمكنك تغيير عدد الاماكن علي الشريحة
                    لسهولة الحسابات لكن ممكن تضيف شريحة جديدة بعدد اماكن جديد)
                </label>
            </div>
            <div class="col-lg-5">
                <mat-form-field class="full_width " appearance="fill" color="accent" floatLabel="always">
                    <input matInput type="number"  class="text_align_right"
                    value="{{this.FG_Add_online_TS.controls['Point_Company_Consumption_No_Of_Time_Slots'].value}}"
                         disabled autocomplete="off">
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-5">
            </div>
            <div class="col-lg-5">
                *
            </div>
        </div>
        <div class="row">
            <div class="col-lg-5  ">
                <label class="General_label ">
                    عدد الشهور التي ترغب في زيادتها
                </label>
            </div>
            <div class="col-lg-5  ">
                <mat-form-field class="full_width" appearance="fill" color="accent" floatLabel="always">
                    <input matInput type="number" formControlName="Point_Company_Consumption_No_months"
                        (input)="calculate()" autocomplete="off" class="text_align_right">
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-5">
            </div>
            <div class="col-lg-5">
                *
            </div>
        </div>
        <div class="row put_border put_margin">
            <div class="col-lg-5  ">
                <label class="General_label ">
                    سعر المكان الواحد لمنتج واحد
                </label>
            </div>
            <div class="col-lg-5  ">
                <mat-form-field class="full_width" appearance="fill" color="accent" floatLabel="always">
                    <input matInput type="number" value="{{this.data.single_product_online_price}}" disabled
                        autocomplete="off" class="text_align_right">
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-5">
            </div>
            <div class="col-lg-5">
                =
            </div>
        </div>
        <div class="row put_margin" [ngClass]="(this.FG_Add_online_TS.controls['Point_Company_Consumption_No_Of_Points'].value > this.data.max_points_count) ? 'border_red' : 'put_border' ">
            <div class="col-lg-5">
                <label class="General_label">
                    عدد النقاط المطلوبة لزيادة الشريحة
                </label>
                <label class="General_label color_red" *ngIf="(this.FG_Add_online_TS.controls['Point_Company_Consumption_No_Of_Points'].value > this.data.max_points_count)">
                    الشركة لا تملك عدد كافي من النقاط
                </label>
            </div>
            <div class="col-lg-5">
                <mat-form-field class="full_width" appearance="fill" color="accent" floatLabel="always">
                    <input matInput type="number" class="text_align_right"
                        value="{{this.FG_Add_online_TS.controls['Point_Company_Consumption_No_Of_Points'].value}}"
                        disabled autocomplete="off">
                </mat-form-field>
            </div>
        </div>
        <div class="row put_border border_green put_margin">
            <div class="col-lg-5">
                <label class="General_label ">
                    عدد النقاط ملك الشركة                    
                </label>
            </div>
            <div class="col-lg-5  ">
                <mat-form-field class="full_width" appearance="fill" color="accent" floatLabel="always">
                    <input matInput class="text_align_right" value="{{this.data.max_points_count}}" disabled autocomplete="off">
                </mat-form-field>
            </div>
        </div>
        <div flex fxLayout="row" fxLayoutAlign="space-between center">
            <button class="btn btn-primary put_margin_not_auto" [disabled]="disable_button" (click)="add(FG_Add_online_TS)">
                زيادة وقت شريحة عرض المنتجات
            </button>
            <button class="btn btn-secondary put_margin_not_auto float_left" (click)="cancel()">
                الغاء
            </button>
        </div>
    </form>
</div>