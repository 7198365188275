<section>
    <div class="container">
        <div class="text_align_center">
            <h1 class=" put_padding put_margin_not_auto">Your Companies and Branches</h1>
            <p>Please select a Company and Branch that you are linked to</p>
            <p>Here you can see All your Companies and Branches that are approved by TheNileZone administration team</p>
        </div>
        <form [formGroup]="FG_Branches" class="required row ">
            <div class="col-lg-4 col-sm-3">
                <div class=" text_align_center">
                    <span class="display_inline_block put_margin_not_auto">
                        <mat-form-field appearance="fill">
                            <mat-label>Your Company
                            </mat-label>
                            <mat-select  (selectionChange)="copmany_id_change($event.value)"
                             autocomplete="off" formControlName="selected_Company_id">
                                <mat-option style="background-color: white;" *ngFor="let data of Company_List" [value]="data.company_id">
                                    {{data.company_name}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="FG_Branches.controls['selected_Company_id'].errors">Please select a Company
                            </mat-error>
                        </mat-form-field>
                    </span>
                </div>
            </div>

            <div class="col-lg-4 col-sm-3">
                <div class=" text_align_center">
                    <span class="display_inline_block put_margin_not_auto">
                        <mat-form-field appearance="fill">
                            <mat-label>Your Branch
                            </mat-label>
                            <mat-select  
                            autocomplete="off" formControlName="selected_Branch_id">
                                <mat-option style="background-color: white;" *ngFor="let data of Branches_List"
                                    [value]="data.user_Related_Branch_Related_Branch">
                                    {{data.branch_name}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="FG_Branches.controls['selected_Branch_id'].errors">Please select a Branch
                            </mat-error>
                        </mat-form-field>
                    </span>
                </div>
            </div>
            <div class="col-lg-4 col-sm-3">
                <div class=" text_align_center">
                    <button class="btn btn-primary put_margin "
                                (click)="Add_New_Products()">
                        Add New Product</button>    
                </div>
            </div>
        <div>
            <div class="row put_margin">
                <div class="text_align_center">
                    <button class="btn btn-primary put_margin "
                        (click)="Show_Approved_Products()">
                        Approved Products</button>
                    <button class="btn btn-primary put_margin "
                        (click)="Show_Under_Revision_Products()">
                        Under Revision Products</button>
                    <button class="btn btn-primary put_margin " 
                        (click)="Show_Rejected_Products()">
                        Rejected Products by TheNileZone</button>                    
                </div>
            </div>
        </div>
        </form>
        <div>
            <div>
                <div class="text_align_center">
                    <div class="put_margin put_border row display_inline_block" *ngFor="let product of products"
                    style="max-width: 220px;width: 100%;">
                        <div >
                            <img src="{{Pictures_Url + 'Product_Pictures/' + product.company_Id + '/' + product.branch_Id + '/' + product.product_Pic_path}}"
                            *ngIf="(product.product_Pic_path)" style="max-width: 100%;" />
                            <img src="{{Pictures_Url + 'Product_Pictures/0/0/Product_Initial_Pic.jpg'}}"
                                *ngIf="!(product.product_Pic_path)" style="max-width: 100%;" />
                        </div>
                        <h3><strong>{{product.product_Name}}</strong> </h3>
                        <div >
                            <button class="btn btn-primary" (click)="view_product(product.product_Id , product.product_Id_Date)">
                                View Product
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>