<section>
    <div class="container ">
        <div class="text_align_center">
            <h1 class="put_margin_not_auto">Your Company/ies</h1>
            <p>Here you can see All your Companies</p>
            <p>Companies may be approved or under revision by TheNileZone Administration Team.</p>
        </div>
        <div class="row put_border put_margin" *ngFor="let company of companies">
            <div class="col">
                <div class="col">
                    <div>
                        <div class="row">
                            <div class="col-lg-9">
                                <div class="man desc">
                                    <h3><strong>Company Name: </strong> {{company.company_Name}}</h3>
                                    <h3><strong>Company Name Arabic: </strong> {{company.company_Name_Arabic}}</h3>
                                    <h3><strong>Company Status: </strong> {{company.company_Statuses_name}}</h3>
                                    <h3><strong>Number of Products on the Front Page: </strong>
                                        {{company.company_No_of_Products_on_front_page}}</h3>
                                    <h3><strong>Number of Online Products: </strong>
                                        {{company.company_No_of_Online_Products}}
                                    </h3>
                                    <h3><strong>Number of Approved Products: </strong>
                                        {{company.company_No_of_approved_Products}}
                                    </h3>
                                    <h3><strong>Number of Pending Products: </strong>
                                        {{company.company_No_of_pending_Products}}
                                    </h3>
                                    <h3><strong>Number of not matched Products: </strong>
                                        {{company.company_No_of_denied_Products}}
                                    </h3>
                                </div>
                            </div>
                            <div class="col-lg-2 put_margin">
                                <img src="{{Pictures_Url + 'Companies_Id/' + company.company_Id + '/' + company.company_logo_path}}"
                                    *ngIf="(company.company_logo_path)" style="max-width: 100%" />

                                <img src="{{Pictures_Url + 'Companies_Id/brand10_resized.webp'}}"
                                    *ngIf="!(company.company_logo_path)" style="max-width: 100%;" />
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="row put_margin">
                            <div class="">
                                <button class="btn btn-primary put_margin "
                                    (click)="dialog_update_company(company.company_Id)">
                                    Update Company</button>
                                <button class="btn btn-primary put_margin "
                                    (click)="dialog_view_company(company.company_Id)">
                                    View Company Details</button>
                                <button class="btn btn-primary put_margin "
                                    (click)="dialog_add_company_photo(company.company_Id)">
                                    Add Company Photo</button>
                                <button class="btn btn-primary put_margin "
                                    (click)="dialog_view_company_photos(company.company_Id)">
                                    View Company Photos</button>
                                <button class="btn btn-danger put_margin "
                                    (click)="dialog_delete_company(company.company_Id)">
                                    Delete Company</button>
                            </div>
                        </div>
                    </div>
                </div>
                <h1 class="text_align_center put_margin_not_auto">Your Branch/es</h1>
                <div class=" container put_border put_margin" *ngFor="let branch of company.branches">
                    <div class="man desc">
                        <h3><strong>Branch Name: </strong> {{branch.branch_Name}}</h3>
                        <h3><strong>Branch Name Arabic: </strong> {{branch.branch_Name_Arabic}}</h3>
                        <h3><strong>Branch Status: </strong> {{branch.branch_status_name}}</h3>
                    </div>
                    <div class="row put_margin">
                        <div class="">
                            <button class="btn btn-danger put_margin float_right"
                                (click)="dialog_delete_branch(branch.branch_ID)">
                                Delete Branch</button>
                            <button class="btn btn-primary put_margin float_right"
                                (click)="dialog_view_branch(branch.branch_ID)">
                                View Branch Details</button>
                            <button class="btn btn-primary put_margin float_right"
                                (click)="dialog_update_branch(branch.branch_ID)">
                                Update Branch</button>
                        </div>
                    </div>
                </div>
                <div class="row put_margin float_right">
                    <div class="">
                        <button class="btn btn-primary put_margin" (click)="dialog_create_branch(company.company_Id)">
                            Create Branch</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="row put_margin">
            <div>
                <button class="btn btn-primary put_margin" (click)="dialog_create_company()">
                    Create Company</button>
            </div>
        </div>
    </div>
</section>