<section class="catalog-pan">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="slider-view" >
                    <img src="{{Pictures_Url + 'Product_Pictures/' + product_Company_Id + '/' + product_Branch_Id + '/' + picPath}}"
                    *ngIf="If_has_pic"  />
                    <img src="{{Pictures_Url + 'Product_Pictures/0/0/Product_Initial_Pic.jpg'}}"
                    *ngIf="!(If_has_pic)" />
                </div>
            </div>
            <div class="col-lg-6 col-md-12 feed pr-view">
                <div class="col-12 mt-4 pl-0 product-section_title">
                    <h2>PRODUCT NAME</h2>
                </div>
                <div class="small-tagline">
                    {{product_name}}
                </div>
                <div class="col-12 mt-4 pl-0 product-section_title">
                    <h2>PRODUCT NAME ARABIC</h2>
                </div>
                <div class="small-tagline">
                    {{product_name_arabic}}
                </div>
                <div class="col-12 mt-4 pl-0 product-section_title">
                    <h2>PRODUCT STATUS</h2>
                </div>
                <div class="small-tagline">
                    {{product_status}}
                </div>
                <div class="col-12 mt-4 pl-0 product-section_title">
                    <h2>DESCRIPTION</h2>
                </div>
                <div class="small-tagline">
                    {{product_description}}
                </div>

                <div class="col-12 mt-4 pl-0 product-section_title">
                    <h2>DESCRIPTION ARABIC</h2>
                </div>
                <div class="small-tagline">
                    {{product_description_arabic}}
                </div>
                <!-- PHOTO GALLERY -->
                <div class="slide-product put_margin put_padding">
                    <owl-carousel-o [options]="customOptions">
                        <ng-container *ngFor="let slide of product_pictures">
                            <ng-template carouselSlide id>
                                <div class="desc padding-xs">
                                    <img src="{{Pictures_Url + 'Product_Pictures/' + product_Company_Id + '/' + product_Branch_Id + '/' + slide.product_Picture_Store_Name}}"
                                        [alt]="slide.product_Related_Archive_Id + slide.product_Related_Archive_Id_Date"
                                        (click)="changeImg(slide.product_Picture_Store_Name,slide.product_Picture_Id,slide.product_Picture_Id_Date)" />
                                </div>
                            </ng-template>
                        </ng-container>
                    </owl-carousel-o>
                </div>

                <!-- COLORS LIST -->
                <div class="text-center links put_margin put_padding">
                    <h2>Select Color</h2>
                    <ul>
                        <li *ngFor="let option of product_colors" class="d-block">
                            <a (click)="changeColor(option.product_Color_Id,option.product_Color_Id_Date)"
                                [routerLink]='null'>
                                {{option.product_Color_Name}}
                            </a>
                        </li>
                    </ul>
                </div>

                <!-- PRODUCT NOTE -->
                <div class="col-12 mt-4 pl-0 product-section_title">
                    <h2>PRODUCT NOTE</h2>
                </div>
                <div class="small-tagline">
                    {{product_note}}
                </div>
                <!-- BUTTONS -->
                <div>
                    <div class="row put_margin">
                        <div class="text_align_center">
                            <button class="btn btn-primary put_margin "
                                routerLink="{{'/main/update_product/' + product_id + '/' + product_id_date}}"
                                routerLinkActive='active'>
                                Update Product</button>
                        </div>
                    </div>
                </div>
            </div>
            
            <!-- BACK BUTTON -->
            <div class="feed pr-view">
                <div class="float_right">
                    <a href="main/products_management" class="back"> Back</a>
                </div>
            </div>
        </div>
    </div>
</section>