<div class="big_Container dialog_confirm_content_container text_align_center row">
    <div class="col-lg-10 col-sm-10">
        <h2>Create a Branch</h2>
    </div>
    <div class="col-lg-1 col-sm-1">
        <span class="material-icons" id="close-icon" (click)="cancel()">close</span>
    </div>
</div>
<div class="container" flex fxLayout="column">
    <form [formGroup]="FG_Branches" class="Medium_Container" flex fxLayout="column">
        <div class="put_border put_padding put_margin_not_auto" fxLayout="column" fxFlex="100">
            <H4 class="text_align_center">Branch Name and Description</H4>


            <div class="required row ">
                <div class="col-lg-6 col-sm-5">
                    <label class="float_right General_label margin_top_right_15">Branch Name</label>
                </div>
                <div class="col-lg-6 col-sm-5">
                    <mat-form-field appearance="fill" color="accent" floatLabel="always">
                        <input matInput formControlName="Branch_Name">
                        <mat-error *ngIf="FG_Branches.controls['Branch_Name'].errors?.required">This field is
                            Mandatory
                        </mat-error>
                        <mat-error *ngIf="FG_Branches.controls['Branch_Name'].errors?.maxlength">Maximum length is
                            25character</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="required row ">
                <div class="col-lg-6 col-sm-5">
                    <label class="float_right General_label margin_top_right_15">Branch Name Arabic</label>
                </div>
                <div class="col-lg-6 col-sm-5">
                    <mat-form-field appearance="fill" color="accent" floatLabel="always">
                        <input matInput formControlName="Branch_Name_Arabic">
                        <mat-error *ngIf="FG_Branches.controls['Branch_Name_Arabic'].errors?.required">This field is
                            Mandatory</mat-error>
                        <mat-error *ngIf="FG_Branches.controls['Branch_Name_Arabic'].errors?.maxlength">Maximum
                            length
                            is 25character</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="required row ">
                <div class="col-lg-6 col-sm-5">
                    <label class="float_right General_label margin_top_right_15">Branch Description</label>
                </div>
                <div class="col-lg-6 col-sm-5">
                    <mat-form-field appearance="fill" color="accent" floatLabel="always">
                        <!-- <input matInput formControlName="Branch_Description"> -->
                        <textarea matInput formControlName="Branch_Description">
                        </textarea>
                        <mat-error *ngIf="FG_Branches.controls['Branch_Description'].errors?.required">This field is
                            Mandatory</mat-error>
                        <mat-error *ngIf="FG_Branches.controls['Branch_Description'].errors?.maxlength">Maximum
                            length
                            is 300character</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="required row ">
                <div class="col-lg-6 col-sm-5">
                    <label class="float_right General_label margin_top_right_15">Branch Description Arabic</label>
                </div>
                <div class="col-lg-6 col-sm-5">
                    <mat-form-field appearance="fill" color="accent" floatLabel="always">
                        <!-- <input matInput formControlName="Branch_Description_Arabic"> -->
                        <textarea matInput formControlName="Branch_Description_Arabic">
                        </textarea>
                        <mat-error *ngIf="FG_Branches.controls['Branch_Description_Arabic'].errors?.required">This
                            field
                            is Mandatory</mat-error>
                        <mat-error *ngIf="FG_Branches.controls['Branch_Description_Arabic'].errors?.maxlength">
                            Maximum
                            length is 300character</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="required row ">
                <div class="col-lg-6 col-sm-5">
                    <label class="float_right General_label margin_top_right_15">Branch Note</label>
                </div>
                <div class="col-lg-6 col-sm-5">
                    <mat-form-field appearance="fill" color="accent" floatLabel="always">
                        <input matInput formControlName="Branch_Note">
                        <mat-error *ngIf="FG_Branches.controls['Branch_Note'].errors?.maxlength">Maximum length is
                            300character</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <!-- <div class="required row ">
                <div class="col-lg-6 col-sm-5">
                    <label class="float_right General_label margin_top_right_15">Is this a Main Branch</label>
                </div>
                <div class="col-lg-6 col-sm-5">
                    <mat-form-field appearance="fill" color="accent" floatLabel="always">
                        <mat-checkbox fxFlex="65" fxLayoutAlign="start center" formControlName="Branch_Is_Main_Branch"
                            class="check_box_style"></mat-checkbox>
                    </mat-form-field>
                </div>
            </div> -->
        </div>

        <div class="put_border put_padding put_margin_not_auto" fxLayout="column" fxFlex="100">
            <h4 class="text_align_center">Branch Address and Location</h4>

            <div class="required row ">
                <div class="col-lg-6 col-sm-5">
                    <label class="float_right General_label margin_top_right_15">Branch Address</label>
                </div>
                <div class="col-lg-6 col-sm-5">
                    <mat-form-field appearance="fill" color="accent" floatLabel="always">
                        <input matInput formControlName="Branch_Address">
                        <mat-error *ngIf="FG_Branches.controls['Branch_Address'].errors?.required">This field is
                            Mandatory</mat-error>
                        <mat-error *ngIf="FG_Branches.controls['Branch_Address'].errors?.maxlength">Maximum
                            length
                            is
                            80character</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="required row ">
                <div class="col-lg-6 col-sm-5">
                    <label class="float_right General_label margin_top_right_15">Branch City</label>
                </div>
                <div class="col-lg-6 col-sm-5">
                    <mat-form-field appearance="fill" color="accent" floatLabel="always">
                        <mat-select formControlName="Branch_City" placeholder="Branch City"
                            (selectionChange)="change_city()">
                            <mat-option style="background-color: white;" value="{{City.city_Id}}" *ngFor="let City of Cities">{{City.city_Name}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="FG_Branches.controls['Branch_City'].errors">Please select an city
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="required row ">
                <div class="col-lg-6 col-sm-5">
                    <label class="float_right General_label margin_top_right_15">Branch Area</label>
                </div>
                <div class="col-lg-6 col-sm-5">
                    <mat-form-field appearance="fill" color="accent" floatLabel="always">
                        <mat-select #Branch_area_id formControlName="Branch_Area" placeholder="Branch Area">
                            <mat-option style="background-color: white;" value="{{Area.area_ID}}" *ngFor="let Area of Areas">{{Area.area_Name}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="FG_Branches.controls['Branch_Area'].errors">Please select an area
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="required row ">
                <div class="col-lg-6 col-sm-5">
                    <label class="float_right General_label margin_top_right_15">Add
                        Google Maps location</label>
                </div>
                <div class="col-lg-6 col-sm-5">
                    <!-- <button class="btn btn-primary" type="button" (click)="open_map(Branch_area_id.value, lat.value, long.value)">Map</button> -->
                    <button class="btn btn-primary" type="button" (click)="open_link()">Link</button>
                    <!-- <button class="btn btn-primary" type="button" (click)="open_manual()">Manual</button> -->
                </div>
            </div>

            <div class="required row ">
                <div class="col-lg-6 col-sm-5">
                    <label class="float_right General_label margin_top_right_15">Branch Latitude</label>
                </div>
                <div class="col-lg-6 col-sm-5">
                    <mat-form-field appearance="fill" color="accent" floatLabel="always">
                        <input matInput formControlName="Branch_Lat" #lat>
                    </mat-form-field>
                </div>
            </div>
            <div class="required row ">
                <div class="col-lg-6 col-sm-5">
                    <label class="float_right General_label margin_top_right_15">Branch Longitude</label>
                </div>
                <div class="col-lg-6 col-sm-5">
                    <mat-form-field appearance="fill" color="accent" floatLabel="always">
                        <input matInput formControlName="Branch_Long" #long>
                    </mat-form-field>
                </div>
            </div>
            <div class="required row ">
                <div class="col-lg-6 col-sm-5">
                    <label class="float_right General_label margin_top_right_15">Is location valid</label>
                </div>
                <div class="col-lg-6 col-sm-5">
                    <mat-form-field appearance="fill" color="accent" floatLabel="always">
                        <input matInput formControlName="Is_valid_loc">
                        <mat-error *ngIf="FG_Branches.controls['Is_valid_loc'].errors">Location is not valid
                        </mat-error>
                    </mat-form-field>
                    <div fxFlex="50" fxLayoutAlign="space-around" appearance="fill" color="accent"
                        floatLabel="always" class="put_padding">
                        <button class="btn btn-primary" (click)="Confirm_location(lat.value, long.value)">Confirm
                            Location</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="put_border put_padding put_margin_not_auto" fxLayout="column" fxFlex="100">
            <H4 class="text_align_center">Company Contacts</H4>

            <div class="required row ">
                <div class="col-lg-6 col-sm-5">
                    <label class="float_right General_label margin_top_right_15">Primary Phone</label>
                </div>
                <div class="col-lg-6 col-sm-5">
                    <mat-form-field appearance="fill" color="accent" floatLabel="always">
                        <input type="number" matInput formControlName="Branch_Phone_1">
                        <mat-error *ngIf="FG_Branches.controls['Branch_Phone_1'].errors?.required">This field is
                            Mandatory</mat-error>
                        <mat-error *ngIf="FG_Branches.controls['Branch_Phone_1'].errors?.maxlength">Maximum
                            length
                            is
                            25character</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="required row ">
                <div class="col-lg-6 col-sm-5">
                    <label class="float_right General_label margin_top_right_15">Second Phone</label>
                </div>
                <div class="col-lg-6 col-sm-5">
                    <mat-form-field appearance="fill" color="accent" floatLabel="always">
                        <input type="number" matInput formControlName="Branch_Phone_2">
                        <mat-error *ngIf="FG_Branches.controls['Branch_Phone_2'].errors?.maxlength">Maximum
                            length
                            is
                            25character</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="required row ">
                <div class="col-lg-6 col-sm-5">
                    <label class="float_right General_label margin_top_right_15">Third
                        Phone</label>
                </div>
                <div class="col-lg-6 col-sm-5">
                    <mat-form-field appearance="fill" color="accent" floatLabel="always">
                        <input type="number" matInput formControlName="Branch_Phone_3">
                        <mat-error *ngIf="FG_Branches.controls['Branch_Phone_3'].errors?.maxlength">Maximum
                            length
                            is
                            25character</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="required row ">
                <div class="col-lg-6 col-sm-5">
                    <label class="float_right General_label margin_top_right_15">Owner
                        Phone</label>
                </div>
                <div class="col-lg-6 col-sm-5">
                    <mat-form-field appearance="fill" color="accent" floatLabel="always">
                        <input type="number" matInput formControlName="Branch_Owner_Phone">
                        <mat-error *ngIf="FG_Branches.controls['Branch_Owner_Phone'].errors?.maxlength">Maximum
                            length
                            is 25character</mat-error>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="big_Container put_margin" style="height: 70px;">
            <div class="float_right put_margin">
                <button class="btn btn-primary" (click)="execute_create()">Create
                    Branch</button>
            </div>
            <div class="float_right put_margin">
                <button (click)="cancel()" class="btn btn-secondary">Back</button>
            </div>
        </div>
    </form>
</div>