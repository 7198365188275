<section class="about-us">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-12">
                <!-- <app-category-side-bar></app-category-side-bar> -->
            </div>
            <div class="col-lg-6 col-md-12 feed">
                <h2>SIGN IN</h2>
                <!-- [formGroup]="this.service.Login_FG" -->
                <form [formGroup]="service.Login_FG">
                    <div class="form-controls">
                        <label>UserName *</label>
                        <mat-form-field appearance="fill" floatLabel="always">
                            <input type="text" matInput formControlName="UserName">
                            <mat-error *ngIf="service.Login_FG.controls['UserName'].errors?.required">This field is
                                Mandatory
                            </mat-error>
                            <mat-error *ngIf="service.Login_FG.controls['UserName'].errors?.maxlength">Maximum length is
                                49
                                character</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="form-controls">
                        <label>Password *</label>
                        <mat-form-field appearance="fill" floatLabel="always">
                            <input autocomplete="off" type="password" matInput formControlName="Password">
                            <mat-error *ngIf="service.Login_FG.controls['Password'].errors?.required">
                                This field is Mandatory</mat-error>
                            <mat-error *ngIf="service.Login_FG.controls['Password'].errors?.minlength">
                                Minimum length is 6 character</mat-error>
                            <mat-error *ngIf="service.Login_FG.controls['Password'].errors?.maxlength">
                                Maximum length is 84 character</mat-error>
                        </mat-form-field>
                    </div>
                    
                    <ngx-recaptcha2 #captchaElem [siteKey]="siteKey" formControlName="recaptcha">
                    </ngx-recaptcha2>
                    <mat-error *ngIf="service.Login_FG.controls['recaptcha'].errors">
                        Please Check the box
                    </mat-error>
                    <div class="form-controls">
                        <button class="btns" (click)="signin()">Login</button>
                    </div>
                    <div class="d-flex flex-column links-fr">
                        <a href="http://www.thenilezone.com/general/blog/forgot-password">Forgot your password?</a>
                        <a href="http://www.thenilezone.com/general/blog/forgot-username">Forgot your username?</a>
                        <a href="http://www.thenilezone.com/general/register">Don't have an account?</a>
                    </div>
                </form>
            </div>
            <div class="col-lg-3 col-md-12">
                <!-- <app-category-side-bar></app-category-side-bar> -->
            </div>
        </div>
    </div>
</section>