<div class="container margin-bottom-50" style="direction: rtl;">
    <p>{{Message[message_id]}}</p>
    <div>
        <p style="vertical-align: middle;">
            اذا كنت معروف ل TheNileZone كتاجر من فضلك سجل دخول لادارة حسابك من 
            <a class="normal_link" routerLink='/ar/out/sign-in' [routerLinkActive]="['active']"> هنا</a>.
        </p>
    </div>
    <div>
        <p style="vertical-align: middle;">
            لتبدأ في وضع منتجاتك علي منصة TheNileZone, من فضلك تواصل مع خدمة العملاء من 
        <a class="normal_link" target="_blank" href='http://www.thenilezone.com/general/contact-us'>هنا</a>.</p>
    </div>
</div>