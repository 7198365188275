<div class="container" style="direction: rtl;">
    <div class="big_Container dialog_confirm_content_container text_align_center row">
        <div class="col-lg-10 col-sm-10">
            <h2 class="uppder_case">صور الشركة</h2>
        </div>
        <div class="col-lg-1 col-sm-1">
            <span class="material-icons" id="close-icon" (click)="closeDialog()">close</span>
        </div>
    </div>

    <div class="put_border put_padding put_margin row">
        <h5 ><strong>أختار صورة</strong></h5>
        <div class="col-lg-6 col-sm-6">
            <div class="put_margin put_border row"
                [ngClass]="{'slot_select' : (picture_id_select === product.company_Picture_Id )}"
                (click)="select_online_ts(product.company_Picture_Id,product.company_Picture_Store_Name,product.company_Picture_Extention)"
                *ngFor="let product of photo_list">
                <h3 class="remove_margin row"><strong class="col-sm-6">أسم الصورة: </strong><p class="col-sm-6">{{product.company_Picture_Display_Name}}</p></h3>
                <h3 class="remove_margin row"><strong class="col-sm-6">تعليق: </strong><p class="col-sm-6">{{product.company_Picture_Comment}}</p></h3>
                <h3 class="remove_margin row"><strong class="col-sm-6">هل تحقيق شخصية لصاحب الشركة؟</strong><p class="col-sm-6">{{product.company_Picture_Is_Owner_Identity}}</p></h3>
                <h3 class="remove_margin row"><strong class="col-sm-6">هل تحقيق شخصية للشركة؟</strong><p class="col-sm-6">{{product.company_Picture_Is_Branch_Identity}}</p></h3>
                <h3 class="remove_margin row"><strong class="col-sm-6">هل لوجو للشركة؟</strong><p class="col-sm-6">{{product.company_Picture_Is_Logo}}</p></h3>
            </div>
        </div>
        <div class="col-lg-5 col-sm-5">
            <img src={{initial_Pic_Name}} alt="Please Select Photo to Upload" class="full_width put_border">
        </div>

    </div>
</div>