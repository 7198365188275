<div class="container margin-bottom-50">
    <p>{{Message[message_id]}}</p>
    <div>
        <p style="vertical-align: middle;">
            If you are known to TheNileZone as a Shop please Log in to start managing your account from 
            <a class="normal_link" routerLink='/out/sign-in' [routerLinkActive]="['active']"> Here</a>.
        </p>
    </div>
    <div>
        <p style="vertical-align: middle;">
            To start putting your products on TheNileZone please contact the Customer Care from 
            <a class="normal_link" target="_blank" href='http://www.thenilezone.com/general/contact-us'>Here</a></p>
    </div>
</div>