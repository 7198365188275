<div class="header-row" id="floatingheader">
    <div class="row">
        <div class="col-lg-3 col-md-12 col-sm-12 logo pl-0" style="width: auto;">
            <img src="{{Pictures_Url + 'logo.webp'}}" alt="Logo" routerLink='/home' />
        </div>
        <div class="col-lg-9 col-md-12 col-sm-12 menu">
            <ul>
                <li><a routerLink='/main/home' [routerLinkActive]="['active']">Your Company</a></li>
                <li><a routerLink='/main/points_management' [routerLinkActive]="['active']">Points Management</a></li>
                <li><a routerLink='/main/products_management' [routerLinkActive]="['active']">Products Management</a></li>
                <li><a routerLink='/main/my_branches' [routerLinkActive]="['active']">My Branches</a></li>
                <li><a href="http://www.thenilezone.com/general/about-us" target="_blank">About Us</a></li>
                <li><a href="http://www.thenilezone.com/general/contact-us" target="_blank">Contact Us</a></li>
            </ul>
        </div>
    </div>
</div>