<div class="top-bar" id="vid">
    <div class="row">
        <div class="col-lg-8 col-md-8 col-12 d-flex align-items-center justify-content-lg-start justify-content-md-around right-items">
            <div class="d-flex align-items-center">
                <div class="margin_right_15">
                    <a href="https://www.facebook.com/thenilezone/" target="_blank">
                        <img src="../../../assets/images/social/icons8-facebook.svg" />
                    </a>
                </div>
                <div class="margin_right_15 d-flex">
                    <p class="mr-2 p-0 m-0">اللغة: </p>
                    <select name="" (change)="lang($event.target.value)" class="txt-box" [value]='1'>
                        <option value="1">عربي</option>
                        <option value="2">أنجليزي</option>
                    </select>
                </div>
            </div>

        </div>
        <div
            class="col-lg-4 col-md-4 col-12 d-flex align-items-center justify-content-lg-end justify-content-md-center left-links pl-0">
            <ul>
                <li> <a target="_blank" href='http://www.thenilezone.com/ar/main/personal_info'>{{username}}</a></li>
                <li> <a (click)="onLogout()">خروج</a></li>
                <li>

                </li>
                <!-- <li>
                    <img src="{{Pictures_Url + 'social/icons8-twitter.svg'}}"/>
                </li> -->
            </ul>
        </div>
    </div>
</div>