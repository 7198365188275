<div class="container" flex fxLayout="column">
    <div class="big_Container row">
        <div class="col-lg-10 col-sm-10">
            <h2>Please enter Google Map Link</h2>
        </div>
        <div class="dialog_confirm_content_container col-lg-1 col-sm-1">
            <span class="material-icons" id="close-icon" (click)="cancel()">close</span>
        </div>
    </div>
    <form class="Medium_Container" [formGroup]="FG_Location_link">
            <div class="put_border put_padding put_margin">
                <div flex fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around" appearance="none"
                    class="required">
                    <label class="General_label" fxFlex="30" fxLayoutAlign="end center"
                        fxLayoutAlign.lt-sm="start center">Google maps link</label>
                    <mat-form-field fxFlex="65" fxLayoutAlign="start start" appearance="fill" color="accent"
                        floatLabel="always">
                        <input matInput formControlName="Link">
                    </mat-form-field>
                </div>
            </div>
            <div flex fxLayout="row">
                <button class="btn btn-primary put_margin_not_auto"
                    (click)="return_location(FG_Location_link)">Submit
                    Location</button>
                <button class="btn btn-secondary put_margin_not_auto" (click)="closeDialog(0,0)">Cancel</button>
            </div> 
    </form>
</div>