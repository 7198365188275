<ng-container *ngIf="language_param == 2">
    <div style="direction: ltr;">
        <app-top-bar></app-top-bar>
        <app-header></app-header>
    </div>
</ng-container>
<ng-container *ngIf="language_param == 1">
    <div style="direction: rtl;">
        <app-arabic-top-bar></app-arabic-top-bar>
        <app-arabic-header></app-arabic-header>
    </div>
</ng-container>
<router-outlet></router-outlet>

<ng-container *ngIf="language_param == 2">
    <div>
        <app-footer></app-footer>
    </div>
</ng-container>
<ng-container *ngIf="language_param == 1">
    <div>
        <app-arabic-footer></app-arabic-footer>
    </div>
</ng-container>