<section >
    <div class="container">
        <h1 class="put_margin">Your Branch/Branches</h1>
        <div class="row put_border put_margin" *ngFor="let company of branches">
            <div class="col-lg-12">
                <div class="man desc">
                    <h3><strong>Company Name: </strong> {{company.company_name}}</h3>
                    <h3><strong>Branch Name: </strong> {{company.branch_name}}</h3>
                    <h3><strong>Branch Name Arabic: </strong> {{company.branch_Name_Arabic}}</h3>
                    <h3><strong>Area Name: </strong> {{company.areas_name}}</h3>                    
                </div>
            </div>
        </div>
    </div>
</section>