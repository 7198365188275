<div class="container" style="direction: rtl;">
    <div class="container margin-bottom-50" style="direction: rtl;">
        <p>{{Message[message_id]}}</p>
    </div>
    <div>
        <p style="vertical-align: middle;">
            للتواصل مع خدمة عملاء TheNileZone اضغط 
            <a class="normal_link" target="_blank" href='http://www.thenilezone.com/general/contact-us'>هنا.</a>
        </p>
    </div>
</div>