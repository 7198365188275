<div class="big_Container row" style="direction: rtl;">
    <div class="col-lg-11 col-sm-11 text_align_center">
        <h2>
            زيادة وقت شريحة الصفحة الرئيسية
        </h2>
        <p>
            شريحة الصفحة الرئيسية تتيح لك انك تحدد منتجاتك علي الصفحة الرئيسية ل TheNileZone, فيقدر الزوار يشوفو منتجاتك اول ما يفتحو صفحة الموقع الرئيسية
        </p>
        <p>
            وزن الشريحة هو عدد المرات اللي منتجك هيظهر بيها علي الصفحة الرئيسية
        </p>
    </div>
    <div class="dialog_confirm_content_container col-lg-1 col-sm-1" >
        <span class="material-icons" id="close-icon" (click)="cancel()">close</span>
    </div>
</div>
<div class="container margin-bottom-50">
    <form [formGroup]="FG_Add_online_TS" class="Medium_Container put_border text_align_center">
        <div class="row" style="direction: rtl;">
            <div class="col-lg-5">
                <label class="General_label">
                    وزن الشريحة
                </label>
                <label class="General_label margin_right_5">
                    (ملحوظة: الشريحة الواحدة تقدر يكون عليها 5 منتجات)
                </label>
                <label class="General_label color_red">
                    (ملحوظة: لا يمكنك تغيير وزن شريحة صفحة رئيسية
                    لسهولة الحسابات لكن ممكن تضيف شريحة جديدة بوزن جديد)
                </label>
            </div>
            <div class="col-lg-5">
                <mat-form-field class="full_width " appearance="fill" color="accent" floatLabel="always">
                    <!-- <input matInput type="number" formControlName="Point_Company_Consumption_No_Of_Time_Slots"
                        (input)="calculate()" autocomplete="off" disabled> -->
                    <input matInput type="number" class="text_align_right"
                    value="{{this.FG_Add_online_TS.controls['Point_Company_Consumption_No_Of_Time_Slots'].value}}"
                         disabled autocomplete="off">
                </mat-form-field>
            </div>
        </div>
        <div class="row text_align_start">
            <div class="col-lg-5">
            </div>
            <div class="col-lg-5">
                *
            </div>
        </div>
        <div class="row" style="direction: rtl;">
            <div class="col-lg-5  ">
                <label class="General_label">
                    عدد الشهور *
                </label>
            </div>
            <div class="col-lg-5  ">
                <mat-form-field class="full_width" appearance="fill" color="accent" floatLabel="always">
                    <input matInput type="number" class="text_align_right"
                    formControlName="Point_Company_Consumption_No_months"
                        (input)="calculate()" autocomplete="off">
                </mat-form-field>
            </div>
        </div>
        <div class="row text_align_start">
            <div class="col-lg-5">
            </div>
            <div class="col-lg-5">
                *
            </div>
        </div>
        <div class="row put_border put_margin" style="direction: rtl;">
            <div class="col-lg-5">
                <label class="General_label">
                    سعر الشريحة لل 5 منتجات (السعر محدد من الموقع)
                </label>
            </div>
            <div class="col-lg-5  ">
                <mat-form-field class="full_width" appearance="fill" color="accent" floatLabel="always">
                    <input matInput type="number" class="text_align_right"
                    value="{{this.data.single_product_online_price}}" disabled
                        autocomplete="off">
                </mat-form-field>
            </div>
        </div>
        <div class="row text_align_start">
            <div class="col-lg-5">
            </div>
            <div class="col-lg-5">
                =
            </div>
        </div>
        <div class="row put_margin" style="direction: rtl;" [ngClass]="(this.FG_Add_online_TS.controls['Point_Company_Consumption_No_Of_Points'].value > this.data.max_points_count) ? 'border_red' : 'put_border' ">
            <div class="col-lg-5">
                <label class="General_label">
                    عدد النقاط المطلوبة لزيادة وقت الشريحة
                </label>
                <label class="General_label color_red" *ngIf="(this.FG_Add_online_TS.controls['Point_Company_Consumption_No_Of_Points'].value > this.data.max_points_count)">The company does not have enough points</label>
            </div>
            <div class="col-lg-5">
                <mat-form-field class="full_width" appearance="fill" color="accent" floatLabel="always">
                    <input matInput type="number" class="text_align_right"
                        value="{{this.FG_Add_online_TS.controls['Point_Company_Consumption_No_Of_Points'].value}}"
                        disabled autocomplete="off">
                </mat-form-field>
            </div>
        </div>
        <div class="row put_border border_green put_margin" style="direction: rtl;">
            <div class="col-lg-5">
                <label class="General_label ">
                    عدد النقاط ملك الشركة
                </label>
            </div>
            <div class="col-lg-5  ">
                <mat-form-field class="full_width" appearance="fill" color="accent" floatLabel="always">
                    <input matInput value="{{this.data.max_points_count}}" class="text_align_right"
                     disabled autocomplete="off">
                </mat-form-field>
            </div>
        </div>
        <div flex fxLayout="row" fxLayoutAlign="space-between center" style="direction: ltr;">
            <button class="btn btn-primary put_margin_not_auto" [disabled]="disable_button" (click)="add(FG_Add_online_TS)">
                زيادة وقت شريحة الصفحة الرئيسية
            </button>
            <button class="btn btn-secondary put_margin_not_auto float_left" (click)="cancel()">
                الغاء
            </button>
        </div>
    </form>
</div>