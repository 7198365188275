<div class="big_Container dialog_confirm_content_container text_align_center row">
    <div class="col-lg-10 col-sm-10">
        <h2>Create your Company </h2>
    </div>
    <div class="col-lg-1 col-sm-1">
        <span class="material-icons" id="close-icon" (click)="cancel()">close</span>
    </div>
</div>
<div class="container">
    <form [formGroup]="formGroup_Create_Company" class="Medium_Container">
    <div class="put_border put_padding put_margin">
        <H4 class="text_align_center">Company Data</H4>

        <div class="required row ">
            <div class="col-lg-6 col-sm-5">
                <label class="float_right General_label margin_top_right_15">Company Name</label>
            </div>
            <div class="col-lg-6 col-sm-5">
                <mat-form-field appearance="fill" color="accent" floatLabel="always">
                    <input matInput formControlName="Company_Name">
                    <mat-error *ngIf="formGroup_Create_Company.controls['Company_Name'].errors?.required">This
                        field is
                        Mandatory
                    </mat-error>
                    <mat-error *ngIf="formGroup_Create_Company.controls['Company_Name'].errors?.maxlength">
                        Maximum
                        length is
                        50character</mat-error>
                    <mat-error *ngIf="formGroup_Create_Company.controls['Company_Name'].errors?.Company_Name_check">
                        Company Name already used</mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="required row ">
            <div class="col-lg-6 col-sm-5">
                <label class="float_right General_label margin_top_right_15">Copmany Name Arabic</label>
            </div>
            <div class="col-lg-6 col-sm-5">
                <mat-form-field appearance="fill" color="accent" floatLabel="always">
                    <input matInput formControlName="Company_Name_Arabic">
                    <mat-error *ngIf="formGroup_Create_Company.controls['Company_Name_Arabic'].errors?.required">This
                        field is
                        Mandatory</mat-error>
                    <mat-error *ngIf="formGroup_Create_Company.controls['Company_Name_Arabic'].errors?.maxlength">
                        Maximum length is
                        50character</mat-error>
                    <mat-error
                        *ngIf="formGroup_Create_Company.controls['Company_Name_Arabic'].errors?.Company_Name_check">
                        Company Arabic Name already used</mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="required row ">
            <div class="col-lg-6 col-sm-5">
                <label class="float_right General_label margin_top_right_15">Company Description</label>
            </div>
            <div class="col-lg-6 col-sm-5">
                <mat-form-field class="full_width" appearance="fill" color="accent" floatLabel="always">
                    <textarea matInput formControlName="Company_Desc">
                    </textarea>
                    <mat-error *ngIf="formGroup_Create_Company.controls['Company_Desc'].errors?.required">This
                        field is
                        Mandatory
                    </mat-error>
                    <mat-error *ngIf="formGroup_Create_Company.controls['Company_Desc'].errors?.maxlength">
                        Maximum
                        length is
                        300character</mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="required row ">
            <div class="col-lg-6 col-sm-5">
                <label class="float_right General_label margin_top_right_15">Copmany Description Arabic</label>
            </div>
            <div class="col-lg-6 col-sm-5">
                <mat-form-field class="full_width" appearance="fill" color="accent" floatLabel="always">
                    <textarea matInput formControlName="Company_Desc_Arabic">
                    </textarea>
                    <mat-error *ngIf="formGroup_Create_Company.controls['Company_Desc_Arabic'].errors?.required">This
                        field is
                        Mandatory
                    </mat-error>
                    <mat-error *ngIf="formGroup_Create_Company.controls['Company_Desc_Arabic'].errors?.maxlength">
                        Maximum length is
                        300character</mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="required row ">
            <div class="col-lg-6 col-sm-5">
                <label class="float_right General_label margin_top_right_15">Primary Phone</label>
            </div>
            <div class="col-lg-6 col-sm-5">
                <mat-form-field appearance="fill" color="accent" floatLabel="always">
                    <input matInput formControlName="Company_Phone_1">
                    <mat-error *ngIf="formGroup_Create_Company.controls['Company_Phone_1'].errors?.required">
                        This field
                        is Mandatory
                    </mat-error>
                    <mat-error *ngIf="formGroup_Create_Company.controls['Company_Phone_1'].errors?.maxlength">
                        Maximum
                        length is
                        25character</mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6 col-sm-5">
                <label class="float_right General_label margin_top_right_15">Second Phone</label>
            </div>
            <div class="col-lg-6 col-sm-5">
                <mat-form-field appearance="fill" color="accent" floatLabel="always">
                    <input matInput formControlName="Company_Phone_2">
                    <mat-error *ngIf="formGroup_Create_Company.controls['Company_Phone_2'].errors?.maxlength">
                        Maximum
                        length is
                        25character</mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6 col-sm-5">
                <label class="float_right General_label margin_top_right_15">Third
                    Phone</label>
            </div>
            <div class="col-lg-6 col-sm-5">
                <mat-form-field appearance="fill" color="accent" floatLabel="always">
                    <input matInput formControlName="Company_Phone_3">
                    <mat-error *ngIf="formGroup_Create_Company.controls['Company_Phone_3'].errors?.maxlength">
                        Maximum
                        length is
                        25character</mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6 col-sm-5">
                <label class="float_right General_label margin_top_right_15">Fourth Phone</label>
            </div>
            <div class="col-lg-6 col-sm-5">
                <mat-form-field appearance="fill" color="accent" floatLabel="always">
                    <input matInput formControlName="Company_Phone_4">
                    <mat-error *ngIf="formGroup_Create_Company.controls['Company_Phone_4'].errors?.maxlength">
                        Maximum
                        length is
                        25character</mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6 col-sm-5">
                <label class="float_right General_label margin_top_right_15">Company Note</label>
            </div>
            <div class="col-lg-6 col-sm-5">
                <mat-form-field appearance="fill" color="accent" floatLabel="always">
                    <input matInput formControlName="Company_Note">
                    <mat-error *ngIf="formGroup_Create_Company.controls['Company_Note'].errors?.maxlength">
                        Maximum length is 300character</mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6 col-sm-5">
                <label class="float_right General_label margin_top_right_15">If
                    Company has Location</label>
                </div>
                <div class="col-lg-6 col-sm-5">
                    <mat-radio-group disabled formControlName="Company_Has_Location">
                        <mat-radio-button [value]=true checked>Yes</mat-radio-button>
                        <mat-radio-button [value]=false >No</mat-radio-button>
                </mat-radio-group>
            </div>
        </div>
        <div class="big_Container put_margin" style="height: 70px;">
                <div class="float_right put_margin">
                    <button (click)="cancel()" class="btn btn-secondary">Back</button>
                </div>
        </div>
    </div>
</form>
</div>