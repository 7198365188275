<div class="big_Container dialog_confirm_content_container text_align_center row" style="direction: rtl;">
    <div class="col-lg-10 col-sm-10">
        <h2>أنشئ شركة جديدة</h2>
    </div>
    <div class="col-lg-1 col-sm-1">
        <span class="material-icons" id="close-icon" (click)="cancel()">close</span>
    </div>
</div>
<div class="container">
    <form [formGroup]="formGroup_Create_Company" class="Medium_Container">
        <div class="put_border put_padding put_margin" style="direction: rtl;">
            <div class="required row ">
                <div class="col-lg-4 col-sm-5">
                    <label class="float_left General_label margin_top_15">أسم الشركة *</label>
                </div>
                <div class="col-lg-8 col-sm-5">
                    <mat-form-field appearance="fill" color="accent" floatLabel="always">
                        <input class="text_align_right" matInput formControlName="Company_Name_Arabic">
                        <mat-error *ngIf="formGroup_Create_Company.controls['Company_Name_Arabic'].errors?.required">
                            هذة الخانة ضرورية
                        </mat-error>
                        <mat-error *ngIf="formGroup_Create_Company.controls['Company_Name_Arabic'].errors?.maxlength">
                            الحد الاقصي من الحروف هو 50
                        </mat-error>
                        <mat-error
                            *ngIf="formGroup_Create_Company.controls['Company_Name_Arabic'].errors?.Company_Name_check">
                            أسم الشركة مستخدم من قبل</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="required row ">
                <div class="col-lg-4 col-sm-5">
                    <label class="float_left General_label margin_top_15">أسم الشركة باللغة الانجليزية *</label>
                </div>
                <div class="col-lg-8 col-sm-5">
                    <mat-form-field appearance="fill" color="accent" floatLabel="always">
                        <input class="text_align_right" matInput formControlName="Company_Name">
                        <mat-error *ngIf="formGroup_Create_Company.controls['Company_Name'].errors?.required">
                            هذة الخانة ضرورية
                        </mat-error>
                        <mat-error *ngIf="formGroup_Create_Company.controls['Company_Name'].errors?.maxlength">
                            الحد الاقصي من الحروف هو 50
                        </mat-error>
                        <mat-error *ngIf="formGroup_Create_Company.controls['Company_Name'].errors?.Company_Name_check">
                            أسم الشركة باللغة الانجليزية مستخدم من قبل</mat-error>
                    </mat-form-field>
                </div>
            </div>            
            <div class="required row ">
                <div class="col-lg-4 col-sm-5">
                    <label class="float_left General_label margin_top_15">وصف الشركة *</label>
                </div>
                <div class="col-lg-8 col-sm-5">
                    <mat-form-field class="full_width" appearance="fill" color="accent" floatLabel="always">
                        <textarea matInput formControlName="Company_Desc_Arabic">
                    </textarea>
                        <mat-error
                            *ngIf="formGroup_Create_Company.controls['Company_Desc_Arabic'].errors?.required">
                            هذة الخانة ضرورية
                        </mat-error>
                        <mat-error *ngIf="formGroup_Create_Company.controls['Company_Desc_Arabic'].errors?.maxlength">
                            الحد الاقصي من الحروف هو 300
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="required row ">
                <div class="col-lg-4 col-sm-5">
                    <label class="float_left General_label margin_top_15">وصف الشركة بالانجليزي *</label>
                </div>
                <div class="col-lg-8 col-sm-5">
                    <mat-form-field class="full_width" appearance="fill" color="accent" floatLabel="always">
                        <textarea matInput formControlName="Company_Desc">
                    </textarea>
                        <mat-error *ngIf="formGroup_Create_Company.controls['Company_Desc'].errors?.required">
                            هذة الخانة ضرورية
                        </mat-error>
                        <mat-error *ngIf="formGroup_Create_Company.controls['Company_Desc'].errors?.maxlength">
                            الحد الاقصي من الحروف هو 300
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="required row ">
                <div class="col-lg-4 col-sm-5">
                    <label class="float_left General_label margin_top_15">رقم تليفون رئيسي *</label>
                </div>
                <div class="col-lg-8 col-sm-5">
                    <mat-form-field appearance="fill" color="accent" floatLabel="always">
                        <input class="text_align_right" matInput type="number" formControlName="Company_Phone_1">
                        <mat-error *ngIf="formGroup_Create_Company.controls['Company_Phone_1'].errors?.required">
                            هذة الخانة ضرورية
                        </mat-error>
                        <mat-error *ngIf="formGroup_Create_Company.controls['Company_Phone_1'].errors?.maxlength">
                            الحد الاقصي من الارقام هو 25
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-4 col-sm-5">
                    <label class="float_left General_label margin_top_15">رقم تليفون ثاني</label>
                </div>
                <div class="col-lg-8 col-sm-5">
                    <mat-form-field appearance="fill" color="accent" floatLabel="always">
                        <input class="text_align_right" matInput type="number" formControlName="Company_Phone_2">
                        <mat-error *ngIf="formGroup_Create_Company.controls['Company_Phone_2'].errors?.maxlength">
                            الحد الاقصي من الارقام هو 25
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-4 col-sm-5">
                    <label class="float_left General_label margin_top_15">رقم تليفون ثالث</label>
                </div>
                <div class="col-lg-8 col-sm-5">
                    <mat-form-field appearance="fill" color="accent" floatLabel="always">
                        <input class="text_align_right" matInput type="number" formControlName="Company_Phone_3">
                        <mat-error *ngIf="formGroup_Create_Company.controls['Company_Phone_3'].errors?.maxlength">
                            الحد الاقصي من الارقام هو 25
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-4 col-sm-5">
                    <label class="float_left General_label margin_top_15">
                        رقم تليفون رابع
                    </label>
                </div>
                <div class="col-lg-8 col-sm-5">
                    <mat-form-field appearance="fill" color="accent" floatLabel="always">
                        <input type="number" matInput formControlName="Company_Phone_4">
                        <mat-error *ngIf="formGroup_Create_Company.controls['Company_Phone_4'].errors?.maxlength">
                            الحد الاقصي من الارقام هو 25
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-4 col-sm-5">
                    <label class="float_left General_label margin_top_15">
                        ملاحظات عن الشركة
                    </label>
                </div>
                <div class="col-lg-8 col-sm-5">
                    <mat-form-field appearance="fill" color="accent" floatLabel="always">
                        <input class="text_align_right" matInput formControlName="Company_Note">
                        <mat-error *ngIf="formGroup_Create_Company.controls['Company_Note'].errors?.maxlength">
                            الحد الاقصي من الحروف هو 300
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-4 col-sm-5">
                    <label class="float_left General_label">
                        هل يوجد مقر للشركة؟
                    </label>
                </div>
                <div class="col-lg-8 col-sm-5">
                    <mat-radio-group formControlName="Company_Has_Location">
                        <mat-radio-button [value]=true checked>نعم</mat-radio-button>
                        <mat-radio-button [value]=false>لا</mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>
            <div class="big_Container put_margin" style="height: 70px;">
                <div class="float_right put_margin">
                    <button class="btn btn-primary" (click)="create_company(formGroup_Create_Company)">
                        أنشئ شركة
                    </button>
                </div>
                <div class="float_right put_margin">
                    <button (click)="cancel()" class="btn btn-secondary">رجوع</button>
                </div>
            </div>
        </div>
    </form>
</div>