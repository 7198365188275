<div class="container">
    <div class="container margin-bottom-50">
        <p>{{Message[message_id]}}</p>
    </div>
    <div>
        <p style="vertical-align: middle;">
            To Contact Customer Care Press
            <a class="normal_link" target="_blank" href='http://www.thenilezone.com/general/contact-us'>Here</a>
        </p>
    </div>
</div>