<div class="container" flex fxLayout="column" style="direction: rtl;">
    <H2>أضافة منتج للفرع</H2>
    <form [formGroup]="formGroup_insert_Archived_Products" class="Medium_Container">
        <div class="put_border put_padding put_margin">
            <H4 class="text_align_center">بيانات المنتج</H4>
            <div class="required row ">
                <div class="col-6">
                    <label class="float_left General_label margin_top_right_15">أسم المنتج</label>
                </div>
                <div class="col-6">
                    <mat-form-field class="full_width" appearance="fill" color="accent" floatLabel="always">
                        <input matInput formControlName="Archived_Product_Name_Arabic" #arabic_name>
                        <mat-error
                            *ngIf="formGroup_insert_Archived_Products.controls['Archived_Product_Name_Arabic'].errors?.required">
                            هذة الخانة ضرورية
                        </mat-error>
                        <mat-error
                            *ngIf="
                        formGroup_insert_Archived_Products.controls['Archived_Product_Name_Arabic'].errors?.maxlength">
                            الحد الاقصي من الحروف هو 50
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="required row ">
                <div class="col-6">
                    <label class="float_left General_label margin_top_right_15">أسم المنتج بالانحليزي</label>
                </div>
                <div class="col-6">
                    <mat-form-field class="full_width" appearance="fill" color="accent" floatLabel="always">
                        <input matInput formControlName="Archived_Product_Name">
                        <mat-error
                            *ngIf="formGroup_insert_Archived_Products.controls['Archived_Product_Name'].errors?.required">
                            هذة الخانة ضرورية
                        </mat-error>
                        <mat-error *ngIf="
                        formGroup_insert_Archived_Products.controls['Archived_Product_Name'].errors?.maxlength">
                            الحد الاقصي من الحروف هو 50
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div flex fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around">
                <div fxFlex="30" class="General_label" fxLayoutAlign="end center" fxLayoutAlign.lt-sm="start center">
                    <label class="General_label" fxFlex="100" fxLayoutAlign="end center"
                        fxLayoutAlign.lt-sm="start center">
                        غير مسموح بتكرار اسم المنتج بالعربي والانجليزي في نفس الفرع
                    </label>
                </div>
            </div>

            <div class="required row ">
                <div class="col-6">
                    <label class="float_left General_label margin_top_right_15">
                        وصف المنتج
                    </label>
                </div>
                <div class="col-6">
                    <mat-form-field class="full_width" appearance="fill" color="accent" floatLabel="always">
                        <input matInput formControlName="Archived_Product_Description_Arabic">
                        <mat-error
                            *ngIf="formGroup_insert_Archived_Products.controls['Archived_Product_Description_Arabic'].errors?.required">
                            هذة الخانة ضرورية
                        </mat-error>
                        <mat-error
                            *ngIf="formGroup_insert_Archived_Products.controls['Archived_Product_Description_Arabic'].errors?.maxlength">
                            الحد الاقصي من الحروف هو 50
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="required row ">
                <div class="col-6">
                    <label class="float_left General_label margin_top_right_15">
                        وصف المنتج بالانجليزي
                    </label>
                </div>
                <div class="col-6">
                    <mat-form-field class="full_width" appearance="fill" color="accent" floatLabel="always">
                        <input matInput formControlName="Archived_Product_Description">
                        <mat-error
                            *ngIf="formGroup_insert_Archived_Products.controls['Archived_Product_Description'].errors?.required">
                            هذة الخانة ضرورية
                        </mat-error>
                        <mat-error
                            *ngIf="
                    formGroup_insert_Archived_Products.controls['Archived_Product_Description'].errors?.maxlength">
                            الحد الاقصي من الحروف هو 50
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="required row ">
                <div class="col-6">
                    <label class="float_left General_label margin_top_right_15">تصنيف 1</label>
                </div>
                <div class="col-6">
                    <mat-form-field appearance="fill" color="accent" floatLabel="always">
                        <mat-select formControlName="cat_1"
                        (selectionChange)="cat_1_change($event)">
                            <mat-option style="background-color: white;" [value]="Cat_1_list_item.product_Catigory_1_Id"
                                *ngFor="let Cat_1_list_item of Cat_1_list">{{Cat_1_list_item.product_Catigory_1_Name_Arabic}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="formGroup_insert_Archived_Products.controls['cat_1'].errors">
                            هذة الخانة ضرورية
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="required row ">
                <div class="col-6">
                    <label class="float_left General_label margin_top_right_15">تصنيف 2</label>
                </div>
                <div class="col-6">
                    <mat-form-field appearance="fill" color="accent" floatLabel="always">
                        <mat-select formControlName="cat_2"
                        (selectionChange)="cat_2_change($event)">
                            <mat-option style="background-color: white;" [value]="Cat_2_list_item.product_Catigory_2_Id"
                                *ngFor="let Cat_2_list_item of Cat_2_list">{{Cat_2_list_item.product_Catigory_2_Name_Arabic}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="formGroup_insert_Archived_Products.controls['cat_2'].errors">
                            هذة الخانة ضرورية
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="required row ">
                <div class="col-6">
                    <label class="float_left General_label margin_top_right_15">تصنيف 3</label>
                </div>
                <div class="col-6">
                    <mat-form-field appearance="fill" color="accent" floatLabel="always">
                        <mat-select formControlName="cat_3" 
                        (selectionChange)="cat_3_change($event)">
                            <mat-option style="background-color: white;" [value]="Cat_3_list_item.product_Catigory_3_Id"
                                *ngFor="let Cat_3_list_item of Cat_3_list">{{Cat_3_list_item.product_Catigory_3_Name_Arabic}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="formGroup_insert_Archived_Products.controls['cat_3'].errors">
                            هذة الخانة ضرورية
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="required row ">
                <div class="col-6">
                    <label class="float_left General_label margin_top_right_15">تصنيف 4</label>
                </div>
                <div class="col-6">
                    <mat-form-field appearance="fill" color="accent" floatLabel="always">
                        <mat-select formControlName="Archived_Product_Related_Catigory_4">
                            <mat-option style="background-color: white;" [value]="Cat_4_list_item.product_Catigory_4_Id"
                                *ngFor="let Cat_4_list_item of Cat_4_list">{{Cat_4_list_item.product_Catigory_4_Name_Arabic}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="formGroup_insert_Archived_Products.controls['Archived_Product_Related_Catigory_4'].errors">
                            هذة الخانة ضرورية
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            
            <div class="required row ">
                <div class="col-6">
                    <label class="float_left General_label margin_top_right_15">سعر المنتج</label>
                </div>
                <div class="col-6">
                    <mat-form-field appearance="fill" color="accent" floatLabel="always">
                        <input min="0" type="number" matInput formControlName="Archived_Products_Price">
                        <mat-error
                            *ngIf="formGroup_insert_Archived_Products.controls['Archived_Products_Price'].errors">
                            هذة الخانة ضرورية
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="required row ">
                <div class="col-6">
                    <label class="float_left General_label margin_top_right_15">ملاحظات علي المنتج</label>
                </div>
                <div class="col-6">
                    <mat-form-field appearance="fill" color="accent" floatLabel="always">
                        <input matInput formControlName="Archived_Product_Note">
                    </mat-form-field>
                </div>
            </div>

            <div class="required row ">
                <div class="col-6">
                    <label class="float_left General_label margin_top_right_15">
                        حالة توصيل المنتج للعميل
                    </label>
                </div>
                <div class="col-6">
                    <mat-form-field appearance="fill" color="accent" floatLabel="always">
                        <mat-select formControlName="Archived_Products_Delivery_Status">
                            <mat-option style="background-color: white;" [value]="item.product_Delivery_Status_Id"
                                *ngFor="let item of prod_delivery_statuses">{{item.product_Delivery_Status_Name_Arabic}}
                            </mat-option>
                        </mat-select>
                        <mat-error
                            *ngIf="formGroup_insert_Archived_Products.controls['Archived_Products_Delivery_Status'].errors">
                            هذة الخانة ضرورية
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="required row ">
                <div class="col-6">
                    <label class="float_left General_label margin_top_right_15">
                        عدد النقاط المطلوبة من الشركة للتوصيل
                    </label>
                </div>
                <div class="col-6">
                    <mat-form-field appearance="fill" color="accent" floatLabel="always">
                        <input min="0" matInput type="number" formControlName="Archived_Product_Company_Delivery_Point">
                        <mat-error
                            *ngIf="formGroup_insert_Archived_Products.controls['Archived_Product_Company_Delivery_Point'].errors">
                            هذة الخانة ضرورية
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="required row ">
                <div class="col-6">
                    <label class="float_left General_label margin_top_right_15">
                        عدد النقاط المطلوبة من الزبون للتوصيل
                    </label>
                </div>
                <div class="col-6">
                    <mat-form-field appearance="fill" color="accent" floatLabel="always">
                        <input min="0" type="number" matInput formControlName="Archived_Product_User_Delivery_Point">
                        <mat-error
                            *ngIf="formGroup_insert_Archived_Products.controls['Archived_Product_User_Delivery_Point'].errors">
                            هذة الخانة ضرورية
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="put_margin text_align_end">
                <div class="">
                    <button class="btn btn-primary " (click)="create_product(formGroup_insert_Archived_Products)">
                        أضافة منتج
                    </button>
                </div>

            </div>
        </div>
    </form>

    <div>
        <div class="float_right">
        </div>
        <div>
            <button class="btn btn-secondary put_margin" routerLink="/ar/main/products_management"
                routerLinkActive='active' class="btn btn-secondary">رجوع</button>
        </div>

    </div>
</div>